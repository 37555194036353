import Parse from '@parse';
import { StripChar } from 'stripchar';
import { ENV } from '@app/env';

export interface IParseFile {
  __type: string;
  name: string;
  url: string;
}

export interface Document {
  file: Parse.File;
  title: string;
}

export interface IDocument {
  file?: ExtendedFile;
  title: string;
  url?: string;
}

/**
 * Extended class from `Parse.File` with additional features
 */
export class ExtendedFile extends Parse.File {
  b64Data: string;
  original: File;

  /**
	 * @param fileOrUrl File object or url to file
	 * @param previewB64 Base64 string. It will be used to generate a temporary url
	 */
  constructor(fileOrUrl: File | string | IParseFile | Parse.File, previewB64?: string) {
    if(!fileOrUrl) throw new Error('Wrong parameter');

    if (!ENV.isServer && fileOrUrl instanceof File) {	// File
      const file = fileOrUrl;

      const strippedName = StripChar.RSExceptUnsAlpNum(file.name.split('.')[0])
      const name = strippedName && strippedName.replace(/\s/g, '_') || '_';
      const extension = file.name.match(/\.[a-zA-Z0-9]+$/)[0] || '';

      super(`${name}${extension}`, file);
      this.b64Data = previewB64;
      this.original = file;

    } else if (typeof fileOrUrl == 'string') {	// Url

      const filename = fileOrUrl.replace(/^.*[\\\/]/, '');
      const strippedName = StripChar.RSExceptUnsAlpNum(filename.split('.')[0])
      const name = strippedName && strippedName.replace(/\s/g, '_') || '_';
      const extension = filename.match(/\.[a-zA-Z0-9]+$/)[0] || '';

      super(`${name}${extension}`, [0]);
      // tslint:disable-next-line: no-any
      (this as any)._url = fileOrUrl;

    } else if (fileOrUrl instanceof Parse.File) { // Parse.File
      super(fileOrUrl.name(), [0]);
      // tslint:disable-next-line: no-any
      (this as any)._url = fileOrUrl.url();

    } else {	// IParseFile
      const name = ExtendedFile.extractFilename(fileOrUrl.name);
      super(name, { url: (fileOrUrl as IParseFile).url });
    }
  }

  name(): string {
    return ExtendedFile.extractFilename(super.name());
  }

  url() {
    return super.url() || this.b64Data;
  }

  /**
	 * Extract the file name from an URL and strip it's UUID prefix.
	 */
  static extractFilename(url: string): string {
    if (!url) return null;
    const filename = url.replace(/^.*[\\\/]/, '').replace(/^\w{32}_/, '');
    const name = filename.split('.')[0];
    const extension = filename.split('.')[1] || '';
    return `${name}${extension && '.' || ''}${extension}`;
  }

  /**
	 * Upload an array of files to the Parse Server;
	 * @param source Temp array for files to be uploaded. It will be emptied after successful save.
	 * @param output Array to store the files successfully saved.
	 */
  static uploadFiles(source: Parse.File[], output: Parse.File[]) {
    const promises = source.map(file => file.save());
    return Promise.all(promises)
      .then((result) => {
        if (!output) output = [];
        output.splice(0, output.length, ...output.concat(result));
        source.splice(0, source.length);
      })
      .catch((errors: { error?: Parse.Error, success?}[]) => {

        if (errors.forEach) {
          errors.forEach((result, i) => {
            if (result.success) {
              if (!output) output = [];

              const savedElements = source.splice(i, 1);
              output.push(...savedElements);
            }
          });
        }

        throw errors;
      });
  }
}

/**
 * This class overwrites some methods and includes functions to
 * manage arrays of files on Parse Server.
 */
export abstract class UsesFilePointers extends Parse.Object {

  //================= Overwritten Methods =====================
  // tslint:disable-next-line: no-any
  save(attrs?: any | null, options?: Parse.Object.SaveOptions): Promise<this> {
    return this.uploadPendingFiles().then(() => super.save(attrs, options));
  }

  abstract uploadPendingFiles(): Promise<void>;

  /**
	 * Adds a file and enqueues it to be uploaded when the Project is saved
	 * @param file File element
	 * @param unsavedArray Temp array to store files before uploading them
	 * @param base64 For images only: b64 encoded data string
	 */
  protected addFile(file: File, unsavedArray: ExtendedFile[], base64?: string) {
    if (!unsavedArray) unsavedArray = [];

    const f = new ExtendedFile(file, base64);
    unsavedArray.push(f);
  }

  /**
	 * Attach a file to item inside a property that's an array of
	 * `IDocument` objects
	 */
  protected attachFile(arr: IDocument[], index: number, file: File) {
    arr[index].file = new ExtendedFile(file);
    arr[index].url = null;
  }

  /**
	 * Retrieves file names from both arrays: saved and unsaved files.
	 */
  protected getFileNames(unsavedArray: ExtendedFile[], savedArray: Parse.File[]) {
    const unsaved = unsavedArray.map(p => p.url());
    const saved = savedArray && savedArray.map(p => p.name()) || [];
    return saved.concat(unsaved);
  }

  /**
	 * Retrieves file urls from both arrays: saved and unsaved files.
	 */
  protected getFileUrls(unsavedArray: ExtendedFile[], savedArray: Parse.File[]) {
    const unsaved = unsavedArray.map(p => p.url());
    const saved = savedArray && savedArray.map(p => p.url()) || [];
    return saved.concat(unsaved);
  }

  /**
	 * Removes a file from the temp array or the stored array.
	 * @param index Based on the get*Urls() array
	 * @param savedArray Property in this Project to store files
	 * @param unsavedArray Temp array to store files
	 */
  protected removeFile(index: number, savedArray: Parse.File[], unsavedArray: ExtendedFile[]) {
    if (!savedArray) savedArray = [];

    if (index < savedArray.length)
      savedArray.splice(index, 1);
    else
      unsavedArray.splice(index - savedArray.length, 1);
  }

  /**
	 * Upload files from the temp array to the Parse Server and updates the store property
	 * @param source Temp array for files to be uploaded. It will be emptied after successful save.
	 * @param output Property array to store the already saved files.
	 */
  protected uploadFilesInTempArray(source: Parse.File[], output: Parse.File[]) {
    return ExtendedFile.uploadFiles(source, output);
  }

  static saveAll<T extends Parse.Object>(list: T[], options?: Parse.Object.SaveAllOptions): Promise<T[]> {
    // tslint:disable-next-line: no-any
    return Promise.all(list.map(p => (p as any as UsesFilePointers).uploadPendingFiles()))
      .then(() => {
        return super.saveAll<T>(list, options);
      })
  }
}

/**
 * Helper class which adds methods for managing structures that include Parse Files.
 * Attach this class to a property in a Parse.Object.
 *
 * ### Usage
 * 1. Call `registerArrayOfDocuments()` from the child constructor to setup
 * the buffer arrays for saving files.
 * 2. Create documents to be uploaded with `pushDocument()`. Call `removeDocument()`
 * to remove them.
 * 3. To access created documents call `getDocuments()`.
 * 4. Attach files to the documents with `attachFileToDocument()`.
 * 5. Use `uploadPendingDocuments()` and `UploadPendingDocumentsAll()` to save the files.
 *
 * @example
 * class MedicalFile extends UsesDocuments {
 * 	constructor() {
 * 		super();
 * 		this.registerArrayOfDocuments('policy');
 * 	}
 * }
 *
 * class Patient extends Parse.Object {
 * 	constructor(attr) {
 * 		super(attr);
 * 		this.set('medicalFile', new MedicalFile());
 * 	}
 *
 *  addCurrentPolicy(file: ExtendedFile) {
 * 		const medicalFile = this.get('medicalFile');
 * 		medicalFile.pushDocument('policy', {title: `Patient's current policy`, file});
 * 	}
 *
 * 	addExpiredPolicy(file: ExtendedFile) {
 * 		const medicalFile = this.get('medicalFile');
 * 		medicalFile.pushDocument('policy', {title: `Patient's expired policy`, file});
 * 	}
 * }
 */
export abstract class UsesDocuments {

  /**
	 * The registry for all properties with saved/unsaved documents.
	 * Use `registerDocumentProperty()` to add them.
	 */
  documentRegistry: {
    [property: string]: {
      saved: IDocument[];
      unsaved: IDocument[];
    }
  } = {};
  // tslint:disable-next-line: no-any
  abstract get(propertyName: string): any;

  isDocumentSaved(propertyName: string, index: number) {
    return index < this.documentRegistry[propertyName].saved.length;
  }
  // tslint:disable-next-line: no-any
  abstract set(propertyName: string, value: any): void;
  // tslint:disable-next-line: no-any
  abstract toJSON(): any;

  /**
	 * Uploads the pending files from an IDocument array
	 * @param propertyName The property of type `Document[]` related to this documents
	 */
  async uploadPendingDocuments(propertyName: string) {
    const prop = this.documentRegistry[propertyName];
    if (!prop)
      throw new Error(`property '${propertyName}' not found in registry. Did you add it with registerDocumentProperty()?`);

    const unsavedDocumentsWithFiles: IDocument[] = prop.unsaved.filter(d => !!d.file);
    const unsavedFiles: ExtendedFile[] = unsavedDocumentsWithFiles.map(d => d.file);
    const savedFiles: ExtendedFile[] = [];

    await ExtendedFile.uploadFiles(unsavedFiles, savedFiles);

    let i = 0;
    let finalDocumentArray: IDocument[] = prop.unsaved
      .map((d, j) => {
        if (d.file) {
          const doc: IDocument = {
            title: d.title || ExtendedFile.extractFilename(savedFiles[i].name()),
            file: d.file && savedFiles[i],
            url: savedFiles[i].url()
          };
          i++;
          return doc;
        } else {
          return d;
        }
      })

    finalDocumentArray = finalDocumentArray.concat(
      prop.saved.map(d => {
        const doc: IDocument = {
          title: d.title,
          file: d.url && new ExtendedFile(d.url) || null,
          url: d.url
        };
        return doc;
      })
    );

    prop.unsaved = [];
    finalDocumentArray.sort((a, b) => {
      if (a.title > b.title || !a.title) return 1;
      if (a.title < b.title || !b.title) return -1;
      return 0;
    });

    prop.saved = finalDocumentArray;
    try {
      this.set(propertyName, prop.saved);
    } catch (e) { }
  }

  /**
	 * Upload all documents registered with `registerDocumentProperty()`
	 */
  async UploadPendingDocumentsAll() {
    const keys = Object.keys(this.documentRegistry);
    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      await this.uploadPendingDocuments(k);
    };
  }

  /**
	 * Attaches a file to a document existing in the registry
	 * @param propertyName Property that holds the document array
	 * @param documentIndex Index of the document in the registry
	 * @param title Title of this document
	 */
  protected attachFileToDocument(propertyName: string, documentIndex: number, file: File, title?: string | null) {
    const arr = this.getDocuments(propertyName);
    if (!arr)
      throw new Error(`property '${propertyName}' not found in registry. Did you add it with registerDocumentProperty()?`);

    const doc = arr[documentIndex];
    doc.title = title != undefined ? title : doc.title;
    if (file != undefined) {
      doc.file = new ExtendedFile(file);
      doc.url = null;
    }

    this.markAsUnsaved(propertyName, documentIndex);
  }

  /**
	 * Retrieves the saved and unsaved documents concatenated in a single array
	 */
  protected getDocuments(propertyName: string): IDocument[] {
    const prop = this.documentRegistry[propertyName];
    if (!prop) return null;

    const savedArray = prop.saved || [];
    const unsavedArray = prop.unsaved || [];
    return savedArray.concat(unsavedArray);
  }

  protected markAsUnsaved(propertyName: string, documentIndex: number) {
    const arr = this.getDocuments(propertyName);
    if (!arr)
      throw new Error(`property '${propertyName}' not found in registry. Did you add it with registerDocumentProperty()?`);

    const doc = arr[documentIndex];
    const prop = this.documentRegistry[propertyName];
    const savedArray = prop.saved || [];
    const unsavedArray = prop.unsaved || [];
    const index = documentIndex;

    if (this.isDocumentSaved(propertyName, documentIndex)) {
      savedArray.splice(index - unsavedArray.length, 1);
      unsavedArray.push(doc);
    }
  }

  /**
	 * Pushes a document into the unsaved array for a property
	 */
  protected pushDocument(propertyName: string, document: IDocument) {
    const prop = this.documentRegistry[propertyName];
    if (!prop)
      throw new Error(`property '${propertyName}' not found in registry. Did you add it with registerDocumentProperty()?`);
    prop.unsaved.push(document);
  }

  /**
	 * Register property of type `IDocument[]` to create its temporary buffers for
	 * managing saved and unsaved files.
	 * @param propertyName The property of type `Document[]` related to this documents
	 */
  protected registerArrayOfDocuments(propertyName: string) {
    const prop: Document[] = this.get(propertyName) || [];
    const saved = prop.filter(d => !!d.file).map(d => {
      return { title: d.title, url: d.file && d.file.url() }
    });

    this.documentRegistry[propertyName] = {
      saved,
      unsaved: []
    };
  }

  /**
	 * Remove a document from the registry
	 * @param propertyName Property that holds the document array
	 * @param index Position of the document in the registry
	 */
  protected removeDocument(propertyName: string, index: number) {
    const prop = this.documentRegistry[propertyName];
    if (!prop)
      throw new Error(`property '${propertyName}' not found in registry. Did you add it with registerDocumentProperty()?`);

    const savedArray = prop.saved || [];
    const unsavedArray = prop.unsaved || [];

    if (this.isDocumentSaved(propertyName, index))
      savedArray.splice(index, 1);
    else
      unsavedArray.splice(index - unsavedArray.length, 1);
  }
}