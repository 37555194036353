import Parse from '@parse';
import { Profile } from '@library/profile/profile';
import { Department } from '@library/department/department';
import { Project } from '@library/project/project';
import * as arrFilterUnique from 'arr-filter-unique';

export interface IProjectCase {
  companyName?: string,
  department?: Department,
  from?: Date,
  members?: Parse.Role,
  project: Project,
  until?: Date,
}

export interface UpdateMemberParams {
  /**
	 * ProjectCase object id
	 */
  id: string
  /**
	 * IDs from Profile objects to add
	 */
  add?: string[],
  /**
	 * IDs from Profile objects to remove
	 */
  remove?: string[]
}

export class ProjectCase extends Parse.Object implements IProjectCase {
  protected membersToAdd: Profile[] = [];
  protected membersToRemove: Profile[] = [];

  constructor(attributes?: IProjectCase) {
    super('ProjectCase', attributes);
  }

  get companyName(): string { return this.get('companyName'); }
  set companyName(val: string) { this.set('companyName', val); }

  get department(): Department { return this.get('department'); }
  set department(val: Department) { this.set('department', val); }

  get from(): Date { return this.project && this.project.getStartingDate(); }

  get members(): Parse.Role { return this.get('members'); }
  set members(val: Parse.Role) { this.set('members', val); }

  protected get individuals(): Profile[] {
    const arr = this.get('individuals');
    if (!arr) this.set('individuals', []);
    return this.get('individuals');
  }

  get project(): Project { return this.get('project'); }
  set project(val: Project) { this.set('project', val); }

  get providers(): { company: string, service: number }[] {
    const arr = this.get('providers');
    if (!arr) this.set('providers', []);
    return this.get('providers');
  }
  set providers(val: { company: string, service: number }[]) { this.set('providers', val); }

  get until(): Date { return this.project && this.project.getClosingDate(); }

  addMember(profile: Profile) {
    const found = !!this.membersToAdd.find(p => p.id == profile.id);
    if (!found)
      this.membersToAdd.push(profile);
  }

  removeMember(profile: Profile) {
    this.membersToRemove.push(profile);
  }

  protected async updateMembers(): Promise<void> {
    if (!this.membersToAdd.length && !this.membersToRemove.length)
      return null;

    const params: UpdateMemberParams = {
      id: this.id,
      add: this.membersToAdd.filter(p => !!p).map(p => p.id),
      remove: this.membersToRemove.filter(p => !!p).map(p => p.id)
    };
    await Parse.Cloud.run('projectCase:setMembers', params);
  }

  getMemberList(): Profile[] {
    return arrFilterUnique(this.individuals, 'id');
  }

  async getMembers(): Promise<Profile[]> {
    if (this.isNew()) return [];
    return Parse.Cloud.run('projectCase:getMembers', { projectCase: this.toPointer() });
  }

  setStartDate(val: Date) {
    if (typeof val == 'string') val = val && new Date(val);
    if (this.project)
      Object.keys(this.project.milestones.riba).forEach(code => {
        this.project.milestones.riba[code].from = val;
      });
  }

  setEndDate(val: Date) {
    if (typeof val == 'string') val = val && new Date(val);
    if (this.project)
      Object.keys(this.project.milestones.riba).forEach(code => {
        this.project.milestones.riba[code].until = val;
      });
  }

  // ============ Overwrite functions ===============
  // tslint:disable-next-line: no-any
  async save(attr?: any, options?: Parse.Object.SaveOptions) {
    let result = await super.save(attr, options)
    await this.updateMembers();
    this.membersToAdd = [];
    this.membersToRemove = [];

    return result;
  }

  static async saveAll<T extends Parse.Object>(list: T[], options?: Parse.Object.SaveAllOptions): Promise<T[]> {
    // Verify date formats
    for (let i = 0; i < list.length; i++) {
      // tslint:disable-next-line: no-any
      const pc = list[i] as any as ProjectCase;
      await pc.updateMembers();
      pc.membersToAdd = [];
      pc.membersToRemove = [];
    }

    // First, save the project, then save the Project Case
    return Project
      .saveAll(list.map(c => {
        // tslint:disable-next-line: no-any
        const project = (c as any as ProjectCase).project;
        return project;
      }))
      .then(() => super.saveAll<T>(list, options));
  }
}

// IMPORTANT: Register as Parse subclass
Parse.Object.registerSubclass('ProjectCase', ProjectCase);
