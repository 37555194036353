import Parse from '@parse';
import { Tender } from '@library/tender/tender';

export interface ITenderLog {
  tender: Tender,
  log: { date: Date, message: string }[]
}

export class TenderLog extends Parse.Object implements ITenderLog {
  constructor(attr?: ITenderLog) {
    super('TenderLog', attr);
  }

  get log(): { date: Date, message: string }[] {
    const log = this.get('log');
    if (!log || !Array.isArray(log)) this.set('log', []);
    return this.get('log');
  }
  set log(val: { date: Date, message: string }[]) { this.set('log', val); }

  get tender(): Tender { return this.get('tender'); }
  set tender(val: Tender) { this.set('tender', val); }

  annotate(message: string) {
    this.log.push({ date: new Date(), message });
  }
}

Parse.Object.registerSubclass('TenderLog', TenderLog);
