export * from './corporate-accreditations';

export interface EnumObject {
  [code: string]: string
}

export interface OptionItem {
  code: string | number,
  name: string
}

/**
 * @param sort When `= name`, the item with code `0` will be added last as it usually represents 'Other'.
 */
export function enumToArray(enumObject: EnumObject, sort: 'name' | 'code' | 'default' = 'default'): OptionItem[] {
  const other = enumObject[0];

  const arr = Object.keys(enumObject)
    .filter(k => k != '0' || sort == 'code')
    .map(k => {
      return {
        code: !!Number(k) ? Number(k) : k,
        name: enumObject[k]
      }
    });

  if (sort !== 'default')
    arr.sort((a, b) => {
      if (a[sort] > b[sort]) return 1;
      if (a[sort] < b[sort]) return -1;
      return 0;
    });

  if (other && sort != 'code') {
    const key = Object.keys(enumObject)[0];
    arr.push({ code: !!Number(key) ? 0 : '0', name: other });
  }
  return arr;
}


//---------------------------------
//		COMPANY/TEAM RELATED
//---------------------------------

export const CompanyTypes: EnumObject = Object.freeze({
  0: 'Other',
  1: 'Developer',
  2: 'Consultancy',
  3: 'Contractor'
});

export const Industries: EnumObject = Object.freeze({
  0: 'Other',
  1: 'Construction / Real Estate',
});

export const CompanySizeRanges: EnumObject = Object.freeze({
  10: '0-10',
  50: '10-50',
  200: '50-200',
  500: '200-500',
  1000: '+500'
});

export const ServicesOffered: EnumObject = Object.freeze({
  1: 'Acoustic Engineer',
  2: 'Architect',
  3: 'BIM Consultant',
  4: 'Building Control',
  5: 'Façade Engineer',
  6: 'Fire Engineer',
  7: 'Lighting Consultant',
  8: 'Planning Consultant',
  9: 'Principal Designer',
  10: 'Project Manager',
  11: 'Quantity Surveyor',
  12: 'Security Consultant',
  13: 'Services Engineer',
  14: 'Structural Engineer',
  15: 'Sustainability Consultant',
  16: 'Street Lighting',
});

export const Accreditations: EnumObject = Object.freeze({
  1: 'ACABE',
  2: 'ACIBSE',
  3: 'ADPPA',
  4: 'AIFireE',
  5: 'AIIRSM',
  6: 'AIOSH',
  7: 'AMCIHT',
  8: 'AMICE',
  9: 'AMIHE',
  10: 'AMIMechE',
  11: 'AMIStructE',
  12: 'APM ChPP',
  13: 'APM PFQ',
  14: 'APM PMQ',
  15: 'APM PPQ',
  16: 'APM PQ',
  17: 'APM RPP',
  18: 'AppIHE',
  19: 'AssocRICS',
  20: 'BREEAM AP',
  21: 'CEng',
  22: 'CEnv',
  23: 'CFIOSH',
  24: 'CIBSE Licentiate',
  25: 'CMIOSH',
  26: 'CSci',
  27: 'EI',
  28: 'EngTech',
  29: 'FCABE',
  30: 'FCIBSE',
  31: 'FCIHT',
  32: 'FCIWEM',
  33: 'FICE',
  34: 'FIET',
  35: 'FIFireE',
  36: 'FIHE',
  37: 'FIIRSM',
  38: 'FIMechE',
  39: 'FIStructE',
  40: 'FREng',
  41: 'FRICS',
  42: 'FSoPHE',
  43: 'GIFireE',
  44: 'GMICE',
  45: 'Grad CABE',
  46: 'Grad IOSH',
  47: 'GradCIHT',
  48: 'GradCIWEM',
  49: 'HonFIET',
  50: 'HonFREng',
  51: 'HonRICS',
  52: 'IEMA',
  53: 'IEng',
  54: 'IMechE',
  55: 'IPMA Level A',
  56: 'IPMA Level B',
  57: 'LCC',
  58: 'LCEA',
  59: 'LEED AP',
  60: 'MAPM',
  61: 'MCABE',
  62: 'MCIArb',
  63: 'MCIBSE',
  64: 'MCIHT',
  65: 'MCIOB',
  66: 'MCIWEM',
  67: 'MICE',
  68: 'MIET',
  69: 'MIFireE',
  70: 'MIHE',
  71: 'MIIRSM',
  72: 'MIStructE',
  73: 'MRICS',
  74: 'MSoPHE',
  75: 'OCDEA',
  76: 'RIBA',
  77: 'SFIIRSM',
  78: 'SIIRSM',
  79: 'SKA Assessor',
  80: 'Tech IOSH',
  81: 'TechCIWEM',
  82: 'TIFireE',
  83: 'TIStructE',
  84: 'TMIET',
  85: 'WELL AP',
  0: 'Other'
});

export const Degrees: EnumObject = Object.freeze({
  1: 'Bachelor\'s Degree',
  2: 'Master\'s Degree',
  3: 'Doctorate',
  0: 'Other',
});

//---------------------------------
//		PROJECT RELATED
//---------------------------------

export const TransporLinks: EnumObject = Object.freeze({
  1: 'Main Roads',
  2: 'Tube Stations',
  3: 'Bus Lines',
});

export const AreaInformation: EnumObject = Object.freeze({
  0: 'Other',
  1: 'Adjacent Users',
  2: 'History',
});


export const TypesOfUse: EnumObject = Object.freeze({
  7: 'Highways',
  1: 'Hotel',
  2: 'Industrial',
  3: 'Leisure',
  4: 'Office / Commercial',
  5: 'Residential',
  6: 'Retail',
});

export const Measurements: EnumObject = Object.freeze({
  1: 'GEA',
  2: 'GIA',
  3: 'NIA'
});

export const LevelsOfConstruction: EnumObject = Object.freeze({
  1: 'Low',
  2: 'Medium',
  3: 'High',
})

export const ProgrammeItems: EnumObject = Object.freeze({
  1: 'Planning Application',
  2: 'Pause',
  3: 'Public Consultation',
  4: 'Investment Committee',
})

export const TypesOfDevelopment: EnumObject = Object.freeze({
  1: 'New-Build',
  2: 'Refurbishment',
  3: 'Demolition',
});

export const RibaStages: EnumObject = Object.freeze({
  0: 'Stage 0',
  1: 'Stage 1',
  2: 'Stage 2',
  3: 'Stage 3',
  4: 'Stage 4',
  5: 'Stage 5',
  6: 'Stage 6',
  7: 'Stage 7',
});

export const MeetingTypes: EnumObject = Object.freeze({
  1: 'Project Progress Meeting',
  2: 'Design Team Meeting',
  3: 'CMT Meeting',
  4: 'Handover Meeting',
  0: 'Other meeting type',
});

export const MeetingRecurrence: EnumObject = Object.freeze({
  1: 'None',
  2: 'Daily',
  3: 'Weekly',
  4: 'Bi-weekly',
  5: 'Monthly',
  6: 'Quarterly',
  7: 'Yearly',
});

export const ProcurementRoutes: EnumObject = Object.freeze({
  1: 'Traditional',
  2: 'Design and build',
  3: 'Construction Management',
  4: 'Management Contracting',
});

export const TypesOfClient: EnumObject = Object.freeze({
  1: 'Property Developer',
  2: 'Owner-Occupier'
});

//---------------------------------
//				TENDER RELATED
//---------------------------------

export const InsuranceTypes: EnumObject = Object.freeze({
  1: 'Professional Indemnity',
  2: 'Employers Liability',
  3: 'Public Liability',
  4: 'Product Liability',
});
