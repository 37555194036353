import { IParseObject } from '@library/parse-object';
import { Prequalification } from '@library/interfaces';
import { Bid } from '@library/bid/bid';
import { ExtendedFile, UsesFilePointers, IParseFile, Document } from '@library/file';
import { Project } from '@library/project/project';
import { ENV } from '@app/env';
import cloneDeep from 'clone-deep';
import { IProjectMilestones, ProjectMilestones } from '@library/milestones';
import { ServicesOffered } from '@library/enums';
import { TenderGetStatsParams, TenderGetStatsResponse } from '@library/cloud/interfaces';
import Parse from '@parse';
import { Profile } from '@library/profile/profile';
import arrayFilterUnique from 'array-filter-unique/dist';
import { TenderLog } from '@library/tender-log/tender-log';

interface SavedDocument {
  /**
	 * Document's description (not the file name necessarily)
	 */
  title: string;
  /**
	 * If the document has been saved this property will exist
	 */
  url: string;
}

interface UnsavedDocument {
  /**
	 * Document's description (not the file name necessarily)
	 */
  title: string;
  /**
	 * IF the document has not been saved yet this property will exist
	 */
  file: ExtendedFile;
}

interface IDocument extends SavedDocument, UnsavedDocument { }

export interface IQualityAssurance {
  approved: {
    profile: Profile,
    date: string
  },
  checked: {
    profile: Profile,
    date: string
  },
  created: {
    profile: Profile,
    date: string
  }
}

export interface ITender extends IParseObject {
  appointedBid?: Bid;
  appointmentFormUrl?: string;
  deadline?: Date;
  // documentsForSignature?: Document[];
  project: Project;
  prequalification?: Prequalification;
  publishDate?: Date;
  refunded?: boolean,
  requiredDocuments?: string[];
  scope?: IProjectMilestones;
  service?: number;
  statements?: string[];
  title?: string;
  qualityAssurance?: IQualityAssurance;
}

export class Tender extends UsesFilePointers implements ITender {
  protected unsavedDocumentsForSignature: UnsavedDocument[] = [];
  protected template: IProjectMilestones;
  protected _milestoneData: ProjectMilestones;

  constructor(attr?: ITender) {
    super('Tender', attr);

    if (!attr) return;
    this.requiredDocuments = attr.requiredDocuments || [];
    this.statements = attr.statements || [];
    this.appointmentFormUrl = attr.appointmentFormUrl;
  }

  get appointedBid(): Bid { return this.get('appointedBid'); }
  get appointmentForm(): ExtendedFile { return this.get('appointmentForm'); }

  /**
	 * (`undefined` if the file has not been saved to the database)
	 */
  get appointmentFormUrl(): string {
    try {
      return this.appointmentForm && this.appointmentForm.url();
    } catch {
      // tslint:disable-next-line: no-any
      return (this.appointmentForm as any as IParseFile).url;
    }
  }
  set appointmentFormUrl(val: string) { this.setAppointmentForm(val); }

  get approvedBy(): Profile { return this.qualityAssurance && this.qualityAssurance.approved && this.qualityAssurance.approved.profile; }

  get checkedBy(): Profile { return this.qualityAssurance && this.qualityAssurance.checked && this.qualityAssurance.checked.profile; }

  get closed(): boolean { return new Date() >= this.deadline; }

  get createdBy(): Profile { return this.qualityAssurance && this.qualityAssurance.created && this.qualityAssurance.created.profile; }

  get deadline(): Date { return this.get('deadline'); }
  set deadline(val: Date) { this.set('deadline', val); }

  get project(): Project { return this.get('project'); }
  set project(val: Project) { this.set('project', val); }

  get prequalification(): Prequalification { return this.get('prequalification'); }
  set prequalification(val: Prequalification) { this.set('prequalification', val); }

  get published(): boolean {
    const acl = this.getACL();
    return !!acl && acl.getPublicReadAccess() && !!this.deadline;
  }

  get publishDate(): Date { return this.get('publishDate'); }
  set publishDate(val: Date) { this.set('publishDate', val); }

  get refunded(): boolean { return this.get('refunded'); }
  set refunded(val: boolean) { this.set('refunded', val); }

  get requiredDocuments(): string[] { return this.get('requiredDocuments'); }
  set requiredDocuments(val: string[]) { this.set('requiredDocuments', val); }

  get scope(): ProjectMilestones {
    if (!this._milestoneData) {
      const data = this.get('scope');
      this._milestoneData = new ProjectMilestones(data);
    }
    return this._milestoneData;
  }
  set scope(val: ProjectMilestones) {
    if (!(val instanceof ProjectMilestones))
      this._milestoneData = new ProjectMilestones(val);
    else
      this._milestoneData = val;
    this.set('scope', val);
  }
  get service(): number { return this.get('service'); }
  set service(val: number) { this.set('service', val); }

  get statements(): string[] { return this.get('statements'); }
  set statements(val: string[]) { this.set('statements', val); }

  /**
	 * @deprecated Use `getServiceName()` instead
	 */
  get title(): string { return this.get('title') || this.getServiceName(); }
  // set title(val: string) { this.set('title', val); }

  get qualityAssurance(): IQualityAssurance { return this.get('qualityAssurance'); }
  set qualityAssurance(val: IQualityAssurance) { this.set('qualityAssurance', val); }

  protected get documentsForSignature(): Document[] { return this.get('documentsForSignature'); }
  protected set documentsForSignature(val: Document[]) { this.set('documentsForSignature', val); }

  /**
	 * Just an alias for the property `documentsForSignature` with keys remapped.
	 * Returns `[]` if `documentsForSignature = null`.
	 */
  protected get savedDocumentsForSignature(): SavedDocument[] {
    return this.documentsForSignature
      && this.documentsForSignature
        .filter(d => !!d.file)
        .map(d => {
          return {
            title: d.title,
            url: d.file && d.file.url()
          }
        })
      || [];
  }

  async appointBid(bid: Bid, feedback: { [bidId: string]: string }): Promise<Bid> {
    const ab = await Parse.Cloud.run('tender:appoint', {
      bid: bid.toPointer(),
      feedback,
      tender: this.toPointer()
    }, { useMasterKey: ENV.isServer });
    this.set('appointedBid', ab);
    return ab;
  }

  getServiceName(): string {
    return ServicesOffered[this.service];
  }

  async getLog(): Promise<TenderLog> {
    const log = await new Parse.Query(TenderLog)
      .equalTo('tender', this)
      .first({ useMasterKey: ENV.isServer })

    return log || new TenderLog({ tender: this, log: [] });
  }

  getStatus(): 'Active' | 'Drafted' | 'Closed' | 'Appointed' {
    if (this.published) {
      return this.appointedBid ? 'Appointed' : this.closed ? 'Closed' : 'Active';
    } else {
      return 'Drafted';
    }
  }

  /**
	 * Return the 3 (or less) profiles who are currently signed in the tender's Quality Assurance
	 */
  getQAProfiles(): Profile[] {
    const qa = this.qualityAssurance;
    return qa && [qa.approved && qa.approved.profile,
    qa.checked && qa.checked.profile,
    qa.created && qa.created.profile]
      .filter(p => !!p)
      .filter(arrayFilterUnique(p => p.id)) || [];
  }

  static getTendersForProject(...projects: Project[]): Promise<Tender[]> {
    if (!projects[0] || projects[0].isNew()) return Promise.resolve([]);

    return new Parse.Query(Tender)
      .matchesQuery('project', new Parse.Query(Project)
        .containedIn('objectId', projects.map(p => p.id)))
      .ascending('deadline')
      .find({ useMasterKey: ENV.isServer })
  }

  addDocumentForSignature(title: string, file: File) {
    const f = file && new ExtendedFile(file);
    const doc = { title, file: f };
    this.unsavedDocumentsForSignature.push(doc);
    return doc;
  }

  setDocumentForSignature(index: number, title: string, file?: File) {
    const doc = this.getDocumentsForSignature()[index];
    doc.title = title;
    if (file != undefined) {
      doc.file = new ExtendedFile(file);
      doc.url = null;
    }
  }

  getDocumentsForSignature(): IDocument[] {
    return (this.savedDocumentsForSignature as IDocument[]).concat(this.unsavedDocumentsForSignature as IDocument[]);
  }

  removeDocumentForSignature(index: number) {
    if (!this.documentsForSignature) return;
    const unsavedArray = this.unsavedDocumentsForSignature;

    if (index < this.documentsForSignature.length)
      this.documentsForSignature.splice(index, 1);
    else
      unsavedArray.splice(index - this.documentsForSignature.length, 1);
  }

  getStats(): Promise<TenderGetStatsResponse> {
    const params: TenderGetStatsParams = { tender: this.toPointer() };
    return Parse.Cloud.run('tender:getStats', params);
  }

  setAppointmentForm(fileOrUrl: File | string | ExtendedFile) {
    const file = fileOrUrl && ((fileOrUrl instanceof ExtendedFile) ? fileOrUrl : new ExtendedFile(fileOrUrl));
    this.set('appointmentForm', file);
  }

  /**
	 * Fetch and copy property `Project.milestones` into `scope`.
	 * @param project Parent project
	 */
  setScopeTemplate(milestones: IProjectMilestones) {
    const source = (milestones instanceof ProjectMilestones) ? milestones.toJSON() : milestones;
    this.template = cloneDeep(source);
    if (!this.scope) this.set('scope', new ProjectMilestones(this.template));
    this.scope.applyTemplate(this.template);
  }

  /**
	 * @param emails Emails of users to invite to bid on the tender
	 */
  async publish(emails?: string[]): Promise<this> {
    if (this.published) console.warn('An already published tender is being modified!');
    await this.save(null, { useMasterKey: ENV.isServer });
    return Parse.Cloud.run('tender:publish', { tender: this.toPointer(), emails }, { useMasterKey: ENV.isServer });
  }

  async unpublish(): Promise<void> {
    console.warn('Bypassing cloud function tender:unpublish');
    if (!this.existed()) return;
    const acl = this.getACL() || new Parse.ACL();
    acl.setPublicReadAccess(false);
    await this.save();
  }

  toJSON(): ITender {
    const json: ITender = super.toJSON();
    json.appointmentFormUrl = this.appointmentFormUrl;	// add missing
    json.scope = this.scope;	// safe parsing
    return json;
  }

  async uploadPendingFiles() {
    const unsavedDocumentsWithFile: UnsavedDocument[] = this.unsavedDocumentsForSignature.filter(d => !!d.file);
    const unsavedFiles: Parse.File[] = unsavedDocumentsWithFile.map(d => d.file);
    const savedFiles: Parse.File[] = [];

    await super.uploadFilesInTempArray(unsavedFiles, savedFiles);

    let docsForSignature: Document[] = unsavedDocumentsWithFile
      .map((d, i) => {
        const doc: Document = {
          title: d.title || ExtendedFile.extractFilename(savedFiles[i].name()),
          file: d.file && savedFiles[i],
        };
        return doc;
      })

    if (this.savedDocumentsForSignature) {
      docsForSignature = docsForSignature.concat(
        this.savedDocumentsForSignature.map(d => {
          const doc: Document = {
            title: d.title,
            file: new ExtendedFile(d.url)
          };
          return doc;
        })
      );
    }

    this.unsavedDocumentsForSignature = [];
    this.documentsForSignature = docsForSignature.sort((a, b) => {
      if (a.title > b.title || !a.title) return 1;
      if (a.title < b.title || !b.title) return -1;
      return 0;
    });
  }
}

// IMPORTANT: Register as Parse subclass
Parse.Object.registerSubclass('Tender', Tender);
