import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { PATH_CONSTANTS } from '../routes';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private auth: AuthService, private router: Router) { }

  /**
	 * Checks whether user is logged in, if not it takes them to log in page
	 * and takes them back after log in.
	 * @param _route (unused)
	 */
  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    await this.auth.ready();
    if (this.auth.isLoggedIn) {
      return true;
    } else {
      this.router.navigate([PATH_CONSTANTS.LOGIN, { url: state.url }], { skipLocationChange: true });
      return false;
    }
  }
}
