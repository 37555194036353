import { Component, OnDestroy } from '@angular/core';
import * as i18nIsoCountries from 'i18n-iso-countries';
import * as moment from 'moment-business-days';
import { AuthService } from './core/services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  subscription = new Subscription();
  firstPageReady = false;

  constructor(private auth: AuthService) {
    i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));

    moment.updateLocale('en', {
      holidayFormat: 'MM-DD',
      holidays: [
        `01-01`,		// Jan 1
        `12-25`,	  // Dec 25
        `12-31`,	  // Dec 31
      ],
      workingWeekdays: [1, 2, 3, 4, 5]
    });

    // Got to main site when user logs out
    this.subscription.add(
      this.auth.loggedOut.subscribe(() => {
        console.log('bye!');
        window.location.href = '//' + window.location.host;
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
