import { Component, Output, EventEmitter } from '@angular/core';
import { PATH_CONSTANTS } from '@app/core/routes';

@Component({
  selector: 'app-missing-service-modal',
  templateUrl: './missing-service-modal.component.html',
  styleUrls: ['./missing-service-modal.component.scss']
})
export class MissingServiceModalComponent {
  @Output() dismiss = new EventEmitter<void>();
  service = '';
  companyConfigPath = `/${PATH_CONSTANTS.COMPANY}`;
}
