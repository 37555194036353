import Parse from '@parse';
import { User } from '@library/user/user';
import { IParseObject } from '@library/parse-object';
import { Accreditation, Award, Seniority, Degree } from '@library/interfaces';
import { ProjectCase } from '@library/project-case/project-case';
import { StripChar } from 'stripchar';
import { ENV } from '@app/env';

export interface IProfile extends IParseObject {
  user: Parse.Pointer,
  firstName?: string,
  lastName?: string,
  accreditations?: Accreditation[],
  awards?: Award[],
  seniority?: Seniority[],
  portfolio?: Parse.Pointer,
  degrees?: Degree[],
  photoUrl?: string
}

export class Profile extends Parse.Object {

  constructor(attributes?: IProfile) {
    super('Profile', attributes);
  }

  get user(): User { return this.get('user'); }
  set user(val: User) { this.set('user', val); }

  get email(): string { return this.user && this.user.email; }

  get firstName(): string { return this.get('firstName'); }
  set firstName(val: string) { this.set('firstName', val); }

  get fullName(): string { return `${this.lastName || ''}${this.lastName && ', ' || ''}${this.firstName || ''}`; }

  get lastName(): string { return this.get('lastName'); }
  set lastName(val: string) { this.set('lastName', val); }

  get photo(): Parse.File { return this.get('photo'); }
  set photo(val: Parse.File) { this.set('photo', val); }

  get photoUrl(): string {
    return this.photo && this.photo.url();
  }
  set photoUrl(url: string) {
    const filename = url.replace(/^.*[\\\/]/, '');
    this.set('photo', {
      __type: 'File',
      name: StripChar.RSExceptUnsAlpNum(filename),
      url: url
    });
  }

  get accreditations(): Accreditation[] {
    const val = this.get('accreditations');
    if (!val) this.set('accreditations', []);
    return this.get('accreditations');
  }
  set accreditations(val: Accreditation[]) { this.set('accreditations', val); }

  get awards(): Award[] {
    const val = this.get('awards');
    if (!val) this.set('awards', []);
    return this.get('awards');
  }
  set awards(val: Award[]) { this.set('awards', val); }

  get seniority(): Seniority[] {
    const val = this.get('seniority');
    if (!val) this.set('seniority', []);
    return this.get('seniority');
  }
  set seniority(val: Seniority[]) { this.set('seniority', val); }

  get degrees(): Degree[] {
    const val = this.get('degrees');
    if (!val) this.set('degrees', []);
    return this.get('degrees');
  }
  set degrees(val: Degree[]) { this.set('degrees', val); }

  get portfolio(): Parse.Relation<Profile, ProjectCase> { return this.get('portfolio'); }
  set portfolio(val: Parse.Relation<Profile, ProjectCase>) { this.set('portfolio', val); }

  async getPortfolio(): Promise<ProjectCase[]> {
    if(!this.user) return [];

    const queryRole = new Parse.Query(Parse.Role).equalTo('users', this.user.toPointer());
    return new Parse.Query(ProjectCase)
      .matchesQuery('members', queryRole)
      .include('project')
      .find({ useMasterKey: ENV.isServer });
  }

  setPhoto(file: File) {
    if (!file) {
      this.photo = null;
      return;
    }

    const name = StripChar.RSExceptUnsAlpNum(file.name);
    const f = new Parse.File(name, file)
    this.photo = f;
  }

  toJSON(): IProfile {
    const json = super.toJSON();
    json.photoUrl = this.photoUrl;
    return json;
  }
}

// IMPORTANT: Register as Parse subclass
Parse.Object.registerSubclass('Profile', Profile);
