import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Company } from '@library/company/company';
import { AuthService } from './auth.service';
import { PATH_CONSTANTS } from '../routes';
import { IntentionalError } from '../models/errors';

/**
 * Retrieves user's company. If none, it will throw an error and
 * take them to create one.
 */
@Injectable()
export class CompanyResolverService implements Resolve<Company> {

  constructor(private auth: AuthService, private router: Router) { }

  /**
	 * Resolve the user's company, if null, redirect to Edit Company page
	 */
  async resolve(route: ActivatedRouteSnapshot): Promise<Company> {
    await this.auth.ready();
    const user = this.auth.currentUser;
    const company = user && await user.getCompany();

    if (!company && route.url[0].path != PATH_CONSTANTS.COMPANY) {
      setTimeout(() => this.router.navigate([PATH_CONSTANTS.COMPANY]));
      throw new IntentionalError(`Create or get invited to a Company`);
    } else {
      const setupComplete = await company.isSetupComplete();
      if (!setupComplete) {
        setTimeout(() => this.router.navigate([PATH_CONSTANTS.COMPANY]));
        throw new IntentionalError(`Please complete your Company details`);
      }
    }
    return company;
  }
}
