import { Injectable, Inject } from '@angular/core';
import * as Parse from 'parse';
import { PARSE_SERVER_CONFIG, ParseServerConfig } from 'src/app/app.config';
import { ENV } from '@app/env';
declare interface W extends Window { Parse }
declare var window: W;

/**
 * Service for initing the Parse Server SDK.
 *
 * Please use a InjectionToken to pass init arguments:
 *
 * @example
 * ```
 * // app.config.ts
 *
 * export interface ParseServerConfig {
 *   appId: string,
 * 	 javascriptKey: string,
 * 	 serverURL: string
 * }
 *
 * export const PARSE_SERVER_CONFIG = new InjectionToken<ParseServerConfig>('app.config');
 * ```
 *
 * @see [Dependency Providers: Non-class dependencies](https://angular.io/guide/dependency-injection-providers#non-class-dependencies)
 */
@Injectable()
export class ParseServerDatabase {
  appId: string;
  javascriptKey: string;
  serverURL: string;

  constructor(@Inject(PARSE_SERVER_CONFIG) config: ParseServerConfig) {
    this.appId = config.appId;
    this.javascriptKey = config.javascriptKey;
    this.serverURL = config.serverURL;
    this.init();
  }

  /**
	 * Init Parse server SDK. Call this before any other function from Parse.
	 */
  init() {


    console.warn(`
		*********************************************************
		*********************************************************

		App is connecting to Parse server on ${this.serverURL}.

		**********************************************************
		**********************************************************`);

    // Init Parse Server
    Parse.initialize(this.appId, this.javascriptKey);
    const parse = require('parse');
    parse.serverURL = this.serverURL;

		// Expose SDK in debug mode
		// tslint:disable-next-line: no-any
		if (ENV.debug) (window as any).Parse = Parse;
	}

  ready(): Promise<boolean> {
    return Promise.resolve(true);
  }


}