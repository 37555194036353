export const environment = {
  production: false
};

export const ENV = {
  production: false,
  debug: true,
  isServer: false,
  parseServerUrl: 'https://parseapi.back4app.com',
  parseAppId: 'O5ZfN6zKm0FYf9sJcurRmIBcSZMd7IP6tBTcKFsV',
  parseJsApiKey: 'kGowB7tf0cQhS6aWriC4GzIsj1QFKbkxL4JQsW0G',
  googleMapsApiKey: ''
};
