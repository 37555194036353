import { Component, OnDestroy, SecurityContext } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { Router } from '@angular/router';
import { Profile } from '@library/profile/profile';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { PATH_CONSTANTS } from '../routes';
import { User } from '@library/user/user';
import { Company } from '@library/company/company';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnDestroy {
  profile: Profile;
  subscription = new Subscription();
  paths = PATH_CONSTANTS;
  private _company: Company;
  _isCompanyComplete = false;
  ready = false;
  isContractor = true;
  showTooltip = false;

  constructor(public auth: AuthService, private router: Router, private domSanitizer: DomSanitizer) {
    this.auth.ready().then(async () => {

      if (this.auth.isLoggedIn)
        this.loadUserData(this.auth.currentUser);

      this.auth.loggedIn.subscribe(async (user: User) => this.loadUserData(user));
      this.auth.companyUpdated.subscribe(async (c: Company) => {
        this._company = c;
        this.updateTooltipValue(c);
        this.ready = true;
      });
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  get company(): Company {
    return this._isCompanyComplete && this._company || null;
  }

  get version(): string {
    const meta = document.getElementsByName('version')[0];
    const describe = meta && meta.getAttribute('content');
    const segments = describe && describe.split('-') || [];
    const version = segments[0] || '';
    const build = segments[1] && segments[1] !== '0' && `(${segments[1]})` || '';
    return version && `v${version}${build}` || '';
  }

  get userName(): string {
    const user = this.auth.currentUser;
    return (this.profile && `${this.profile.firstName}`)
      || (user && user.getEmail())
      || '';
  }

  get profilePic() {
    const url = this.profile && this.profile.photoUrl || 'https://picsum.photos/150';
    const style = `url('${this.domSanitizer.sanitize(SecurityContext.URL, url)}')`;
    return this.domSanitizer.bypassSecurityTrustStyle(style);
  }

  get companyName() {
    return this.company && this.company.name;
  }

  logout() {
    return this.auth.logOut();
  }

  goto(path: string) {
    this.router.navigateByUrl(path);
  }

  private async loadUserData(user: User) {
    this.profile = await user.getProfile();
    this._company = await user.getCompany();
    this.updateTooltipValue(this._company);
    this.ready = true;
  }

  private async updateTooltipValue(company: Company) {
    this.isContractor = !!company && company.isContractor;
    if (company) {
      this._isCompanyComplete = await company.isSetupComplete();
      const projects = await company.getProjects();
      this.showTooltip = this.isContractor && projects.length < 1;
    }
  }
}
