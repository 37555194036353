import { RibaStages, enumToArray } from './enums';
import { DateRange } from './interfaces';
import moment from 'moment';

export interface CFlow {
  budget: number,
  days: number,
  month: string,
  YYYYMM: number
}

export interface IProjectMilestones {
  other: ProgrammeItem[]
  riba: {
    0: IRibaStage
    1: IRibaStage
    2: IRibaStage
    3: IRibaStage
    4: IRibaStage
    5: IRibaStage
    6: IRibaStage
    7: IRibaStage
  },
}

export interface ICashFlow {
  /**
	 * Currency ISO code
	 */
  currency: string,
  /**
	 * Monthly budget relative to the start of the task.
	 */
  monthly?: number[],
  /**
	 * Hourly rate
   * @deprecated
	 */
  rate?: number
}

export class CashFlow implements Required<ICashFlow> {
  currency: string;
  monthly: number[] = [];
  /** @deprecated in favor of `monthly` */
  rate: number;

  constructor(params: ICashFlow) {
    this.currency = params && params.currency;
    this.monthly = params && params.monthly || [];
    this.rate = params && params.rate;
  }

  getTotal() {
    return this.monthly &&
      this.monthly.length &&
      this.monthly.reduce((prev, cur) => prev + cur) || 0;
  }

  toJSON(): ICashFlow {
    return {
      currency: this.currency,
      monthly: this.monthly,
      rate: this.rate,
    }
  }
}

export interface IJob {
  /**
	 * The desired budget or fee for this item.
	 */
  budget: {
    currency: string
    target: number
  },
  /**
	 * This field is required when `description = undefined`. This shloud matche
	 * to an enum of known items or jobs.
	 * @see ProgrammeItems
	 */
  code?: number
  /**
	 * Use this field if there is no asigned `code`
	 */
  description?: string
  tasks?: ICashFlow[]
}

export class Job implements Required<IJob> {
  budget: {
    currency: string,
    target: number
  };
  code: number;
  description: string;
  tasks: CashFlow[];

  constructor(params?: IJob) {
    this.tasks = params && params.tasks && params.tasks.map(t => new CashFlow(t)) || [];

    if (!params) return;
    this.code = params.code;
    this.description = params.description;
    this.budget = params.budget;
  }

  addTask(months?: number) {
    const task = new CashFlow({
      currency: this.budget && this.budget.currency,
      monthly: new Array(months).fill(null)
    })
    if (!this.tasks) this.tasks = [];
    this.tasks.push(task);
    return task;
  }

  getMonthTotal(monthIndex: number): number {
    const monthly: number[] = this.tasks &&
      this.tasks.map(t => t.monthly && t.monthly[monthIndex] || 0) || [];

    return monthly.length && monthly.reduce((prev, cur) => prev + cur) || 0;
  }

  getTotal(): number {
    const taskTotals = this.tasks && this.tasks.map(t => t.getTotal()) || [];
    if (!taskTotals.length) return 0;
    if (taskTotals.length < 2) return taskTotals[0];

    return taskTotals.length && taskTotals.reduce((prev, cur) => prev + cur) || 0;
  }

  toJSON(): IJob {
    return {
      code: this.code,
      description: this.description,
      budget: this.budget,
      tasks: this.tasks && this.tasks.map(t => t && t.toJSON()) || []
    }
  }
}

export interface ProgrammeItem extends DateRange, IJob { }

export interface IRibaStage extends DateRange {
  budget?: {
    currency: string,
    /**
		 * The expected total cost for the stage
		 */
    target: number
  }
  scope: IJob[],
}

export class RibaStage implements Required<IRibaStage> {
  budget: {
    currency: string,
    target: number
  };
  from: Date;
  scope: Job[] = [];
  until: Date;

  constructor(params?: IRibaStage) {
    this.scope = params && params.scope && params.scope.map(j => new Job(j)) || [];

    if (!params) return;
    if (params.from) {
      // tslint:disable-next-line: no-any
      const from = (params.from as any).iso || params.from;
      this.from = new Date(from || 0) != new Date(0) && new Date(from);
    }
    if (params.until) {
      // tslint:disable-next-line: no-any
      const until = (params.until as any).iso || params.until;
      this.until = new Date(until || 0) != new Date(0) && new Date(until);
    }
    this.budget = params.budget || { currency: null, target: null };
  }

  /**
	 * Pushes a new job into the scope array
	 * @returns The created job
	 */
  addJob(targetBudget?: number): Job {
    const job: Job = new Job({
      budget: {
        currency: this.getCurrency(),
        target: targetBudget
      },
      code: null,
      description: null,
    });

    this.scope.push(job);
    return job;
  }

  getAverageTargetMonthlyRate(): number {
    if (!this.from || !this.until) return NaN;

    const end = this.from && moment(this.from);
    const until = this.until && moment(this.until);
    const months = end.diff(until, 'months');
    return this.getTotal() / months;
  }

  /**
   * Return a single job combining all the rest
   */
  reduceJobs() {
    if (this.scope.length < 1)
      return null;
    if (this.scope.length < 2)
      return this.scope[0];

    return this.scope.reduce((ja, jb) => {
      const bdgA = ja.budget && ja.budget.target || 0;
      const bdgB = jb.budget && jb.budget.target || 0;

      return new Job({
        budget: {
          currency: this.getCurrency(),
          target: bdgA + bdgB
        },
        code: ja.code,
        description: [ja.description, jb.description].filter(d => !!d).join('; '),
      })
    });
  }

  /**
	 * @param mode Use `'target'` to average the stage's target budget.
	 * Use `'fee'` to average the total fee of thi stage's jobs.
	 */
  getCashflow(mode: 'target' | 'fee'): CFlow[] {
    const months = this.listMonths();
    if (!months) return [];

    let totalDays = 0;
    months.forEach(m => totalDays += m.days);

    const avgDailyRate = (mode == 'target' ? this.budget.target : this.getTotal()) / totalDays;
    return months.map(m => {
      return {
        ...m,
        budget: avgDailyRate * m.days
      }
    }) as CFlow[];
  }

  getCurrency(): string {
    return this.budget && this.budget.currency;
  }

  getEndDate(): Date {
    return this.until && new Date(this.until) != new Date(0) && new Date(this.until) || null;
  }

  getStartDate(): Date {
    return this.from && new Date(this.from) != new Date(0) && new Date(this.from) || null;
  }

  /**
	 * Returns the sum of all job totals
	 */
  getTotal(): number {
    const scope = this.scope || [];
    if (!scope.length) return 0;

    if (scope.length > 1) return this.scope.map(s => s.getTotal())
      .reduce((a, b) => (a || 0) + (b || 0))
    else
      return scope[0] && scope[0].getTotal() || 0;
  }

  listMonths(): Partial<CFlow>[] {
    const stage = this;
    if (!stage.from || !stage.until) return null;

    const cashflow: Partial<CFlow>[] = [];
    let startDate = moment(stage.from);
    const endDate = moment(stage.until);
    const completeMonths = Math.floor(
      moment(endDate)
        .startOf('month')
        .diff(
          moment(startDate)
            .endOf('month'),
          'month'
        )
    );

    // Add days from first month
    const startOfNextMonth = moment(startDate).endOf('month').add(1, 'day');
    cashflow.push({
      month: startDate.format('MMM \'YY'),
      days: (endDate > startOfNextMonth ? startOfNextMonth : endDate).diff(moment(stage.from), 'day'),
      YYYYMM: +startDate.format('YYYYMM')
    });

    // Add complete months
    startDate = startOfNextMonth;
    for (let i = 0; i < completeMonths; i++) {
      const monthFlow = {
        month: startDate.format('MMM \'YY'),
        days: startDate.daysInMonth(),
        YYYYMM: +startDate.format('YYYYMM')
      };
      cashflow.push(monthFlow);
      startDate = startDate.endOf('month').add(1, 'day');
    }

    // Add remaining days
    startDate = startDate.startOf('month').subtract(1, 'day');
    if (endDate >= startDate) cashflow.push({
      month: endDate.format('MMM \'YY'),
      days: endDate.diff(startDate, 'day'),
      YYYYMM: +endDate.format('YYYYMM')
    })

    return cashflow;
  }

  toJSON(): IRibaStage {
    return {
      budget: this.budget,
      from: this.from,
      scope: this.scope && this.scope.map(j => j.toJSON()) || [],
      until: this.until,
    };
  }

  /**
	 * Creates a new Job structure using the budget and dates from a
	 * RIBA stage.
	 */
  static createJob(ribaStageData: IRibaStage): Job {
    return new RibaStage(ribaStageData).addJob();
  }
}

export class ProjectMilestones implements Required<IProjectMilestones> {
  // tslint:disable-next-line: no-any
  other: ProgrammeItem[] = [];
  riba: {
    0: RibaStage
    1: RibaStage
    2: RibaStage
    3: RibaStage
    4: RibaStage
    5: RibaStage
    6: RibaStage
    7: RibaStage
    // tslint:disable-next-line: no-any
  } = {} as any;

  constructor(params?: IProjectMilestones) {
    const ribaData: { [code: number]: IRibaStage } = params && params.riba || {};

    const keyArray: string[] = enumToArray(RibaStages, 'code').map(s => s.code.toString());
    keyArray.forEach(k => {
      this.riba[k] = new RibaStage(ribaData[k]);
    });

    this.other = params && params.other || [];
  }

  /**
	 * Applies the date range to all RIBA stages
	 */
  applyTemplate(milestoneTemplate: IProjectMilestones) {
    const ribaTemplate: { [code: number]: IRibaStage } = milestoneTemplate && milestoneTemplate.riba || {};

    Object.keys(ribaTemplate).forEach(k => {
      const stage: RibaStage = this.riba[k] || new RibaStage(ribaTemplate[k]);
      const stageTemplate: IRibaStage = ribaTemplate[k];

      if (stageTemplate) {
        if (stageTemplate.from) {
          const from = (stageTemplate.from instanceof Date) ? stageTemplate.from : stageTemplate.from.iso;
          stage.from = new Date(from || 0) != new Date(0) && new Date(from);
          if (isNaN(stage.from.getTime())) {
            stage.from = null;
          }
        }
        if (stageTemplate.until) {
          const until = (stageTemplate.until instanceof Date) ? stageTemplate.until : stageTemplate.until.iso;
          stage.until = new Date(until || 0) != new Date(0) && new Date(until);
          if (isNaN(stage.until.getTime())) {
            stage.until = null;
          }
        }
      }
      this.riba[k] = stage;
    });
  }

  getBudget() {
    const jobTotals = Object.keys(this.riba)
      .map(k => {
        const stage: RibaStage = this.riba[k];
        return stage.budget.target;
      });

    if (!jobTotals.length)
      return 0;

    if (jobTotals.length > 1)
      return jobTotals.reduce((a, b) => (a || 0) + (b || 0));
    else
      return jobTotals[0];
  }

  getRibaArray(): RibaStage[] {
    return Object.keys(this.riba).map(k => this.riba[k]);
  }

  getTotal() {
    const jobTotals = Object.keys(this.riba)
      .map(k => {
        const stage: RibaStage = this.riba[k];
        return stage.getTotal();
      });

    if (!jobTotals.length)
      return 0;

    if (jobTotals.length > 1)
      return jobTotals.reduce((a, b) => (a || 0) + (b || 0));
    else
      return jobTotals[0];
  }

  /**
   * Reduces all jobs in RIBA stages to a single one
   */
  reduceRibaJobs() {
    Object.keys(this.riba).map(k => {
      const stage: RibaStage = this.riba[k];
      stage.scope = [stage.reduceJobs()];
    });
  }

  toJSON(): IProjectMilestones {
    const json: IProjectMilestones = {
      // tslint:disable-next-line: no-any
      riba: {} as any,
      other: this.other,
    };

    Object.keys(this.riba).forEach(k => {
      json.riba[k] = new RibaStage(this.riba[k]).toJSON();
    });
    return json;
  }
}
