import Parse from '@parse';
import { Project } from '@library/project/project';
import { Company } from '@library/company/company';
import { ExtendedFile } from '@library/file';
import { ENV } from '@app/env';
import { Profile } from '@library/profile/profile';
import { NDARequestAproveParams } from '@library/cloud/interfaces';

export interface INDARequest {
  approvedBy?: Profile,
  bidder: Company,
  project: Project,
  signedFileUrl?: string,
}

export class NDARequest extends Parse.Object {

  constructor(params?: INDARequest) {
    super('NDARequest', params);
  }

  get approvedBy(): Profile { return this.get('approvedBy'); }

  get bidder(): Company { return this.get('bidder'); }
  set bidder(val: Company) { this.set('bidder', val); }

  get project(): Project { return this.get('project'); }
  set project(val: Project) { this.set('project', val); }

  get signedFile(): ExtendedFile {
    const f = this.get('signedFile');
    if (f && !(f instanceof ExtendedFile)) {
      this.set('signedFile', new ExtendedFile(f))
    }
    return this.get('signedFile');
  }
  set signedFile(val: ExtendedFile) { this.set('signedFile', val); }

  get signedFileUrl(): string { return this.signedFile && this.signedFile.url(); }

  /**
	 * All the submitted NDA this user has access to
	 * @param include Keys to include
	 */
  static getAll(include: string[] = ['project']) {
    return new Parse.Query(NDARequest)
      .ascending('updated')
      .include(include)
      .find({ useMasterKey: ENV.isServer });
  }

  /**
	 * All the submitted NDA signed by a company
	 * @param include Keys to include
	 */
  static getSignedBy(company: Company, include: string[] = ['project']) {
    return new Parse.Query(NDARequest)
      .doesNotMatchQuery('project', new Parse.Query(Project)
        .equalTo('company', company))
      .ascending('updated')
      .include(include)
      .find({ useMasterKey: ENV.isServer });
  }

  /**
	 * Get all signed NDA received for a project
	 */
  static async getAllForProject(project: Project) {
    if (project.isNew()) return [];

    return new Parse.Query(NDARequest)
      .equalTo('project', project)
      .include('approvedBy,bidder')
      .find({ useMasterKey: ENV.isServer });
  }

  /**
	 * Get the signed NDA sent by the user (or their company)
	 * to a specific project.
	 */
  static async getForProject(project: Project) {
    return new Parse.Query(NDARequest)
      .equalTo('project', project)
      .first({ useMasterKey: ENV.isServer });
  }

  approve(approve: boolean) {
    const params: NDARequestAproveParams = {
      approve,
      nda: this.toPointer(),
    };
    return Parse.Cloud.run('nda:approve', params);
  }

  cancel() {
    return Parse.Cloud.run('nda:cancel', { id: this.id });
  }
}


// IMPORTANT: Register as Parse subclass
Parse.Object.registerSubclass('NDARequest', NDARequest);
