import { Injectable, EventEmitter } from '@angular/core';
import { User } from '@library/user/user';
import { SignupData } from '@library/interfaces';
import { ParseServerDatabase } from './database.service';
import Parse from '@parse';
import { NDARequest } from '@library/nda-request/nda-request';
import { Company } from '@library/company/company';
import { SettingsService } from './settings.service';
import * as Sentry from '@sentry/browser';
import { IntentionalError } from '../models/errors';

export enum AUTH_ERROR_CODES {
  EMAIL_NOTFOUND,
  EMAIL_UNVERIFIED
}

export class AuthError extends Error {
  constructor(public message: string, public code: AUTH_ERROR_CODES) {
    super(message);
    this.name = 'AuthError';
    Object.setPrototypeOf(this, AuthError.prototype);
  }
}

@Injectable()
export class AuthService {
  companyUpdated: EventEmitter<Company> = new EventEmitter<Company>();
  loggedIn: EventEmitter<User> = new EventEmitter<User>();
  loggedOut: EventEmitter<void> = new EventEmitter<void>();
  signedNDAs: NDARequest[] = [];
  protected _ready: Promise<void>;
  protected _currentUser: User;

  constructor(private parseServer: ParseServerDatabase, private settings: SettingsService) {
    this._ready = this.init();
  }

  async init() {
    await this.parseServer.ready();
    const current = User.current();

    if (current) {
      this._currentUser = new User(current);

      Sentry.configureScope((scope) => {
        scope.setUser({ email: this._currentUser.getUsername() });
      });

      this._currentUser.unset('emailVerified'); // Unset to avoid error on server
      console.log('Welcome back ', this.currentUser.username);
      NDARequest.getAll().then(list => this.signedNDAs = list);

      // Unset to avoid error on server
      this._currentUser.unset('emailVerified');

      // Sign in to last selected company
      const companyId = this.settings.data.auth.companyId;
      if (companyId) try {
        await this._currentUser.setCompany(companyId);
      } catch (e) {
        console.warn(e);
      }
    }
  }

  get currentUser(): User {
    return this._currentUser;
  }

  get isLoggedIn(): boolean {
    return User.current() != null;
  }

  ready(): Promise<void> {
    return this._ready;
  }

  signup(data: SignupData) {
    return User.signupUser(data);
  }

  async logIn(username: string, password: string) {
    try {
      const response = await User.logIn(username, password);

      Sentry.configureScope((scope) => {
        scope.setUser({ email: response.getUsername() });
      });

      const user = await new User(response).fetch();

      if (!user.emailVerified) {
        await User.logOut();
        const err = new AuthError('Email unverified', AUTH_ERROR_CODES.EMAIL_UNVERIFIED);
        throw err;
      }
      // Unset to avoid error on server
      user.unset('emailVerified');

      console.log('logged in', user.getUsername());
      this._currentUser = user;
      NDARequest.getAll().then(list => this.signedNDAs = list);
      this.loggedIn.emit(user);
      return this._currentUser;
    }
    catch (error) {
      // Wrong password?
      if (error instanceof Parse.Error) {
        if (error.code == Parse.ErrorCode.OBJECT_NOT_FOUND) {
          // Change default error type
          error = new IntentionalError(error.message);
        }
      }
      throw error;
    }
  }

  logOut() {
    return User.logOut().then(() => {
      console.log('logout');
      this._currentUser = null;
      this.loggedOut.emit();
    }).catch(() => this.loggedOut.emit());
  }

  /**
	 * Update company data from current user and send it in companyUpdated event
	 */
  triggerCompanyUpdated() {
    this.currentUser.getCompany().then(c => this.companyUpdated.emit(c));
  }

  async SignInToCompany(companyId: string) {
    if (!this.currentUser) throw new IntentionalError('Not logged in');
    const currentCompany = await this.currentUser.setCompany(companyId);
    this.triggerCompanyUpdated();
    console.debug('Switched to ', currentCompany && currentCompany.name);
    this.settings.set({ auth: { companyId } });
    this.settings.save();
    return currentCompany;
  }
}
