import * as assigndeep from 'assign-deep';

export interface IEvaluationCriteria {
  qualitative: {
    _weight: number,
    organization: {
      _weight: number,
      projectExperience: number,
      serviceVariety: number,
      awards: number,
      accreditations: number,
      relationship: number,
    },
    individuals: {
      _weight: number,
      projectExperience: number,
      yearsOfExperience: number,
      awards: number,
      accreditations: number,
      relationship: number,
    },
  },

  quantitative: number,
  isSet: boolean,
}

export class EvaluationCriteria implements IEvaluationCriteria {
  /**
	 * Must manually be set to `true` when
	 * the users saves their criteria  the first time
	 */
  isSet = false;

  constructor(params?: IEvaluationCriteria) {
    if (!params) return;
    if (typeof params.quantitative != 'number') {
      // tslint:disable-next-line: no-any
      params.quantitative = (params.quantitative as any)._weight || 0;
    }
    assigndeep(this, params);
  }

  qualitative = {
    /** (0-100 scale) */
    _weight: 50,
    organization: {
      /** (0-100 scale) */
      _weight: 50,
      projectExperience: 20,
      serviceVariety: 20,
      awards: 20,
      accreditations: 20,
      relationship: 20,
      get total(): number {
        const o = this;
        const total = o.projectExperience
          + o.serviceVariety
          + o.awards
          + o.accreditations
          + o.relationship;
        return isNaN(total) ? null : total;
      },
      set total(_val) { }
    },
    individuals: {
      /** (0-100 scale) */
      _weight: 50,
      projectExperience: 20,
      yearsOfExperience: 20,
      awards: 20,
      accreditations: 20,
      relationship: 20,
      get total(): number {
        const o = this;
        const total = o.projectExperience
          + o.yearsOfExperience
          + o.awards
          + o.accreditations
          + o.relationship;
        return isNaN(total) ? null : total;
      },
      set total(_val) { }
    },
    get total(): number {
      const total = this.organization._weight + this.individuals._weight;
      return isNaN(total) ? null : total;
    },
    set total(_val) { }
  };

  /** (0-100 scale) */
  quantitative = 50;

  get total(): number {
    const total = (this.qualitative && this.qualitative._weight || 0) +
      // tslint:disable-next-line: no-any
      (this.quantitative && (this.quantitative as any)._weight || this.quantitative || 0);	// A previous model used to have _weight property
    return isNaN(total) ? null : total;
  }
  set total(_val) { }

  /**
	 * Get values in 0 to 1 scale
	 */
  normalize(): EvaluationCriteria {
    // tslint:disable-next-line: no-any
    return this.normalizeValues(this) as any;
  }

  private normalizeValues(values: number | Object) {
    const result: { [key: string]: number | Object } = {};
    Object.keys(values).forEach(k => {
      const value = values[k];
      if (typeof value == 'number') {
        result[k] = value / 100;
      } else {
        result[k] = value && this.normalizeValues(value);
      }
    });
    return result;
  }
}
