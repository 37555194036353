import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { PATH_CONSTANTS } from '@app/core/routes';

import { AuthGuardService } from './core/services/auth-guard.service';
import { CompanyResolverService } from './core/services/company-resolver.service';

const routes: Routes = [
  {
    path: PATH_CONSTANTS.LOGIN,
    loadChildren: () => import('src/app/pages/login-page/login-page.module').then(m => m.LoginPageModule)
  },
  {
    path: PATH_CONSTANTS.BENCHMARK,
    loadChildren: () => import('src/app/modules/benchmark/benchmark.module').then(m => m.BenchmarkModule),
    canActivate: [AuthGuardService],
    resolve: { company: CompanyResolverService }
  },
  {
    path: PATH_CONSTANTS.COMPANY,
    loadChildren: () => import('src/app/modules/company-info/company-info.module').then(m => m.CompanyInfoModule),
    canActivate: [AuthGuardService]
  },
  {
    path: PATH_CONSTANTS.PROFILE,
    loadChildren: () => import('src/app/pages/profile-page/profile-page.module').then(m => m.ProfilePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: PATH_CONSTANTS.PROJECTS,
    loadChildren: () => import('src/app/modules/project-details/project-details.module').then(m => m.ProjectDetailsModule),
  },
  {
    path: PATH_CONSTANTS.TENDER_DASHBOARD,
    loadChildren: () => import('src/app/modules/tender-dashboard/tender-dashboard.module').then(m => m.TenderDashboardModule),
    canActivate: [AuthGuardService],
    resolve: { company: CompanyResolverService }
  },
  {
    path: PATH_CONSTANTS.EMPLOYER_DASHBOARD,
    loadChildren: () => import('src/app/modules/employer-dashboard/employer-dashboard.module').then(m => m.EmployerDashboardModule),
    canActivate: [AuthGuardService],
    resolve: { company: CompanyResolverService }
  },
  {
    path: PATH_CONSTANTS.ACCOUNT,
    loadChildren: () => import('src/app/modules/account/account.module').then(m => m.AccountModule),
    canActivate: [AuthGuardService]
  },
  // {
  //   path: PATH_CONSTANTS.POLICIES,
  //   loadChildren: () => import('src/app/modules/policies/policies.module').then(m => m.PoliciesModule),
  // },
  {
    path: '',
    loadChildren: () => import('src/app/pages/home-page/home.module').then(m => m.HomeModule),
  },
  {
    path: '**',
    loadChildren: () => import('src/app/pages/not-found-page/not-found-page.module').then(m => m.NotFoundPageModule),
  }
]

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  enableTracing: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
