import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ParseServerDatabase } from './services/database.service';
import { NotifierModule } from 'angular-notifier';
import { ErrorHandlerService } from './services/error-handler.service';
import { AuthService } from '@app/core/services/auth.service';
import { BootstrapModalModule } from 'ng6-bootstrap-modal';
import * as Bootstrap from 'bootstrap';
import { AlertModalComponent } from '@app/core/alert/alert-modal.component';
import { RouterModule } from '@angular/router';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { ENV } from '@app/env';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ParseServerConfig, PARSE_SERVER_CONFIG } from '../app.config';
import { ModalNDAComponent } from '@app/modules/project-details/components/modal-nda/modal-nda.component';
import { MissingServiceModalComponent } from '@app/modules/tender/components/missing-service-modal/missing-service-modal.component';
import { GestureConfig } from '@angular/material/core';
import { AuthGuardService } from './services/auth-guard.service';
import { SettingsService } from './services/settings.service';
import { CompanyResolverService } from './services/company-resolver.service';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { PaymentService } from './services/payment.service';
import { StripeCheckoutModule } from 'ng-stripe-checkout';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import localeEnGB from '@angular/common/locales/en-GB';

// the second parameter 'fr' is optional
registerLocaleData(localeEnGB, 'en-GB');

const parseServerConfig: ParseServerConfig = {
  appId: ENV.parseAppId,
  javascriptKey: ENV.parseJsApiKey,
  serverURL: ENV.parseServerUrl
};

// Load module
Bootstrap;

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    AlertModalComponent,        	// Custom alert dialog body
    MissingServiceModalComponent,
    ModalNDAComponent,
    SideMenuComponent,
  ],
  entryComponents: [
    AlertModalComponent,
    MissingServiceModalComponent,	// This is here because ngx-modal is not working with dynamic factorycompnents
    ModalNDAComponent,						// This is here because ngx-modal is not working with dynamic factorycompnents
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    ModalModule.forRoot(),				// ngxBoostrap modal
    BootstrapModalModule,         // Alert service
    StripeCheckoutModule,
    TooltipModule.forRoot(),
    NotifierModule.withConfig({   // Notifications
      behaviour: {
        autoHide: 2500
      },
      position: {
        horizontal: {
          position: 'middle'
        },
        vertical: {
          position: 'top',
          distance: 60
        }
      }
    }),
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NotifierModule,
    BootstrapModalModule,
    SideMenuComponent,
  ],
  providers: [
    AuthGuardService,
    AuthService,
    CompanyResolverService,
    ParseServerDatabase,
    SettingsService,
    PaymentService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    { provide: PARSE_SERVER_CONFIG, useValue: parseServerConfig },
    { provide: LOCALE_ID, useValue: 'en-GB' }
  ]
})
export class CoreModule { }
