import Parse from '@parse';
import { IParseObject } from '@library/parse-object';
import { Account } from '@library/account/account';
import { StripChar } from 'stripchar';
import { Award, Accreditation, Address } from '@library/interfaces';
import { Department } from '@library/department/department';
import { ENV } from '@app/env';
import { Project } from '@library/project/project';
import { Industries, CompanySizeRanges, Accreditations } from '@library/enums';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { User } from '@library/user/user';
import { CompanyCloudRequestParams, RoleHasUserParams } from '@library/cloud/interfaces';
import { Profile } from '@library/profile/profile';
import { Tender } from '@library/tender/tender';
import * as arrFilterUnique from 'arr-filter-unique';

export interface ICompany extends IParseObject {
  account?: Account,
  address?: Address,
  admins?: Parse.Role;
  city?: string,
  companyNumber: string,
  country?: string,
  employees?: number,
  industry?: number,
  logoUrl?: string,
  name?: string,
  phone?: string,
  type?: number,
  website?: string,
  year?: number,
  zip?: string,
}

export class Company extends Parse.Object implements ICompany {

  constructor(attr?: ICompany) {
    super('Company', attr);
  }

  get account(): Account { return this.get('account'); }
  set account(val: Account) { this.set('account', val); }

  get name(): string { return this.get('name'); }
  set name(val: string) { this.set('name', val); }

  /**
	 * Internal code for this company's type
	 * @see CompanyTypes
	 */
  get type(): number { return this.get('type'); }
  set type(val: number) { this.set('type', val); }

  get companyNumber(): string { return this.get('companyNumber'); }
  set companyNumber(val: string) { this.set('companyNumber', val); }

  get address(): Address { return this.get('address'); }
  set address(val: Address) { this.set('address', val); }

  get fullAddress(): string {
    const street = this.address && `${this.address.number || ''} ${this.address.street || ''}${this.address.street && ',' || ''}` || '';
    const city = this.city && `${this.city},` || '';
    const zip = this.zip && `${this.zip}` || '';
    return `${street} ${city} ${zip}${
      ((this.address && this.address.street) || this.city || this.zip) && '.' || ''
      } ${this.countryName || ''}`;
  }

  get city(): string { return this.get('city'); }
  set city(val: string) { this.set('city', val); }

  get zip(): string { return this.get('zip'); }
  set zip(val: string) { this.set('zip', val); }

  /**
	 * Country alpha2 code
	 */
  get country(): string { return this.get('country'); }
  set country(val: string) { this.set('country', val.toUpperCase()); }

  get countryName(): string {
    return this.country && i18nIsoCountries.getName(this.country.toUpperCase(), 'en');
  }

  get isContractor() {
    return this.type == 1 || this.type == 3;
  }

  get year(): number { return this.get('year'); }
  set year(val: number) { this.set('year', val); }

  /**
	 * Number of employees in the company
	 * @see CompanySizeRanges
	 */
  get employees(): number { return this.get('employees'); }
  set employees(val: number) { this.set('employees', val); }

  /**
	 * Size of the company expressed in a range number of employees
	 */
  get sizeRange(): string {
    if (this.employees == null) return null;

    const keys = Object.keys(CompanySizeRanges);

    const key = keys.find(k => {
      const n = parseInt(k);
      return this.employees < n;
    }) || keys.length - 1;

    return CompanySizeRanges[key];
  }

  get logo(): Parse.File { return this.get('logo'); }
  set logo(val: Parse.File) { this.set('logo', val); }

  get logoUrl(): string {
    const file: Parse.File = this.get('logo');
    return file && file.url();
  }
  set logoUrl(val: string) {
    const name = StripChar.RSExceptUnsAlpNum(val);
    this.set('logo', {
      type: '__File',
      name: name,
      url: val
    });
  }

  /**
	 * Internal code for this company's industry
	 * @see Industries
	 */
  get industry(): number { return this.get('industry'); }
  set industry(val: number) { this.set('industry', val); }

  get industryName(): string {
    return Industries[this.industry] || null;
  }

  get admins(): Parse.Role { return this.get('admins'); }
  set admins(val: Parse.Role) { this.set('admins', val); }

  /**
   * A role pointing to users and other roles that belong to this company.
   * To retrieve all members related to the company its best to call
   * `getMembers()`, as this function recursively call all department roles.
   * */
  get members(): Parse.Role { return this.get('members'); }
  set members(val: Parse.Role) { this.set('members', val); }

  get phone(): string { return this.get('phone'); }
  set phone(val: string) { this.set('phone', val); }

  get website(): string {
    let url: string = this.get('website');
    if (!url) return '';
    url = url.trim();
    const regex = new RegExp(/^(http(s)?:\/\/)/i);
    if (regex.test(url))
      url = url.replace(regex, 'http$2://');
    else
      url = 'http://' + url;

    return url;
  }
  set website(val: string) { this.set('website', val); }

  /**
	 * Checks if user is in `admins` role or in any other nested role.
	 */
  hasAdminPrivileges(user: Parse.User): Promise<boolean> {
    if (!this.admins)
      throw new Error(`Admins is ${this.admins}. Did you forget to fetch this company?`);

    const params: RoleHasUserParams = {
      user: user.toPointer(),
      role: this.admins.toPointer(),
      depth: 3
    }
    return Parse.Cloud.run('role:hasUser', params);
  }

  async isSetupComplete(): Promise<boolean> {
    if (this.industry === null) return false;
    if (this.isContractor) return true;
    const deps = await this.getDepartmentsQuery()
      .exists('service')
      .select('')
      .find({ useMasterKey: ENV.isServer });
    return deps.filter(d => !d.deleteOn).length > 0;
  }

  setLogo(file: File) {
    const name = StripChar.RSExceptUnsAlpNum(file.name);
    const f = new Parse.File(name, file);
    this.set('logo', f);
  }

  /**
	 * Checks if user is in `admins` role. It does not check
	 * nested roles.
	 */
  isAdmin(user: User): Promise<boolean> {
    return this.admins.getUsers().query().find()
      .then(users => {
        return !!users.find(u => u.id == user.id);
      });
  }

  async countOpportunities(): Promise<number> {
    const qProjects = new Parse.Query('Project')
      .notEqualTo('company', this);

    const services = await this.getServices();
    const qTenders = new Parse.Query('Tender')
      .containedIn('service', services)
      .greaterThan('deadline', new Date())
      .exists('publishDate')
      .doesNotExist('appointedBid')


    if (!this.isNew()) qTenders.matchesQuery('project', qProjects);

    const ops = await qTenders
      .include('project')
      .select('project')
      .find();

    return ops
      .map(t => t.get('project'))
      // Filter unique projects
      .filter((p, i, arr) => arr.findIndex(pp => pp.id == p.id) == i)
      // Active projects
      // tslint:disable-next-line: no-any
      .filter(p => (p as any).isActive())
      .length;
  }

  async getDepartments(service?: number): Promise<Department[]> {
    if (this.isNew()) return [];

    return this.getDepartmentsQuery(service)
      .ascending('name')
      .include('admins,members')
      .find({ useMasterKey: ENV.isServer });
  }

  getDepartmentsQuery(service?: number): Parse.Query<Department> {
    const query = new Parse.Query(Department)
      .equalTo('company', this.toPointer());

    if (service != null) query.equalTo('service', service);
    return query;
  }

  /**
	 * Get members in all departments (not company admins)
	 */
  async getMembers(): Promise<Profile[]> {
    if (this.isNew()) return [];
    const params: CompanyCloudRequestParams = { company: this.toPointer() };
    return Parse.Cloud.run('company:getMembers', params, { useMasterKey: ENV.isServer });
  }

  /**
	 * Get all projects for this company to which the user has access to
	 * @param simplified If `true`, it will only fetch basic attribute fields
	 */
  async getProjects(simplified = false): Promise<Project[]> {
    if (this.isNew()) return [];

    const q = new Parse.Query(Project)
      .ascending('title')
      .equalTo('company', this.toPointer());

    if (simplified) q.select('objectId');
    return q.find({ useMasterKey: ENV.isServer });
  }

  /**
	 * @returns Array of numbers expressing the internal codes for the provided services
	 * @see ServicesOffered
	 */
  async getServices(): Promise<number[]> {
    if (this.isNew()) return [];

    const departments = await new Parse.Query(Department)
      .select('service')
      .equalTo('company', this.toPointer())
      .find({ useMasterKey: ENV.isServer });

    return departments.map(d => d.service);
  }

  /**
	 * @returns Array of numbers expressing the internal codes for its departments' accreditations
	 * @see Accreditations
	 */
  async getAccreditations(): Promise<number[]> {
    if (this.isNew()) return [];

    const departments = await new Parse.Query(Department)
      .select('accreditations')
      .equalTo('company', this.toPointer())
      .find({ useMasterKey: ENV.isServer });

    const acc: Accreditation[] = [];
    departments.forEach(d => acc.push(...d.accreditations));
    return arrFilterUnique(acc, 'code')
      .sort((a, b) => {
        if (Accreditations[a.code] < Accreditations[b.code]) return 1;
        if (Accreditations[a.code] > Accreditations[b.code]) return -1;
        return 0;
      })
      .map(a => a.code)
  }

  /**
	 * @returns Array of numbers expressing the internal codes for its departments' accreditations
	 * @see Awards
	 */
  async getAwards(): Promise<Award[]> {
    if (this.isNew()) return [];

    const departments = await new Parse.Query(Department)
      .select('awards')
      .equalTo('company', this.toPointer())
      .find({ useMasterKey: ENV.isServer });

    const awards: Award[] = [];
    departments.forEach(d => awards.push(...d.awards));
    return awards.sort((a, b) => {
      if (a.year < b.year) return 1;
      if (a.year > b.year) return -1;
      return 0;
    });
  }

  /**
	 * Get all tenders for this company to which the user has access to
	 * @param simplified it `true` Only the field `project` (and the bassic attributes) will be fetched
	 */
  async getTenders(simplified = false): Promise<Tender[]> {
    if (this.isNew()) return [];

    const q = new Parse.Query(Tender)
      .matchesQuery('project', new Parse.Query(Project)
        .equalTo('company', this.toPointer()))
      .ascending('title');

    if (simplified) q.select('project');
    return q.find({ useMasterKey: ENV.isServer });
  }

  toJSON() {
    const json = super.toJSON();
    json.logoUrl = this.logoUrl;
    return json;
  }
}

// IMPORTANT: Register as Parse subclass
Parse.Object.registerSubclass('Company', Company);
