import { Component, SecurityContext } from '@angular/core';
import { DialogComponent, DialogService } from 'ng6-bootstrap-modal';
import { DomSanitizer } from '@angular/platform-browser';

export interface ConfirmModel {
  title?: string;
  message?: string;
  accept?: {
    text: string,
    role?: 'danger' | 'success'
  };
  cancelText?: string;
}

/**
 * A simple accept/cancel dialog.
 *
 * ### Usage ###
 * @example
 *
 * constructor(private dialogService:DialogService) {
 *  showConfirm() {
 *    let disposable = this.dialogService.addDialog(AlertModalComponent, {
 *      title:'Confirm title',
 *      message:'Confirm message',
 *      accept: { text: 'Delete', role: 'danger' }
 *    });
 *
 *    disposable.subscribe((isConfirmed)=>{
 *          if(isConfirmed) {
 *            alert('accepted');
 *          }
 *          else {
 *            alert('declined');
 *          }
 *        });
 *  }
 */
@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel {

  title: string;
  message = 'Are you sure?';
  accept: {
    text: string,
    role?: 'danger' | 'success'
  };
  cancelText: string;

  constructor(dialogService: DialogService, private domSanitizer: DomSanitizer) {
    super(dialogService);
  }

  get htmlMessage() {
    return this.domSanitizer.sanitize(SecurityContext.HTML, this.message);
  }

  confirm() {
    // we set dialog result as true on click on confirm button,
    // then we can get dialog result from caller code
    this.result = true;
    this.close();
  }
}
