import Parse from '@parse';
import { IParseObject } from '@library/parse-object';
import { Company } from '@library/company/company';
import { Profile } from '@library/profile/profile';
import { Accreditation, Award } from '@library/interfaces';
import { ProjectCase } from '@library/project-case/project-case';
import { ENV } from '@app/env';
import { DepartmentCloudRequestParams, DepartmentInviteMemberParams, RoleHasUserParams } from '@library/cloud/interfaces';
import { ServicesOffered } from '@library/enums';
import { PendingInvite } from '@library/pending-invite/pending-invite';
import * as moment from 'moment';

export interface IDepartment extends IParseObject {
  name?: string,
  company: Company,
  service?: number,
  accreditations?: Accreditation[],
  awards?: Award[],
  admins?: Parse.Role,
  members?: Parse.Role,
}

export class Department extends Parse.Object {

  constructor(attr?: IDepartment) {
    super('Department', attr);
    if (!attr) return;

    this.accreditations = attr.accreditations || [];
    this.awards = attr.awards || [];
  }

  get accreditations(): Accreditation[] { return this.get('accreditations'); }
  set accreditations(val: Accreditation[]) { this.set('accreditations', val); }

  get admins(): Parse.Role { return this.get('admins'); }
  set admins(val: Parse.Role) { this.set('admins', val); }

  get awards(): Award[] { return this.get('awards'); }
  set awards(val: Award[]) { this.set('awards', val); }

  get company(): Company { return this.get('company'); }
  set company(val: Company) { this.set('company', val); }

  get deleteOn(): Date { return this.get('deleteOn'); }

  get service(): number { return this.get('service'); }
  set service(val: number) { this.set('service', val); }

  get members(): Parse.Role { return this.get('members'); }
  set members(val: Parse.Role) { this.set('members', val); }

  get name(): string { return this.get('name'); }
  set name(val: string) { this.set('name', val); }

  async addAdmin(member: Profile) {
    console.warn('Bypassing function department:addAdmin');
    this.admins.getUsers().add(member.user);
    this.members.getUsers().remove(member.user);
    await Promise.all([
      this.admins.save(null, { useMasterKey: ENV.isServer }),
      this.members.save(null, { useMasterKey: ENV.isServer })
    ]);
  }

  /**
	 * Get profiles for users with admin privileges (not only in the admin property role)
	 */
  async getAdmins(): Promise<Profile[]> {
    const params: DepartmentCloudRequestParams = { department: this.toPointer() };
    return Parse.Cloud.run('department:getAdmins', params);
  }

  async getPendingInvites(): Promise<PendingInvite[]> {
    return new Parse.Query(PendingInvite)
      .equalTo('department', this.toPointer())
      .find({ useMasterKey: ENV.isServer });
  }

  /**
	 * Recursive query for users with admin privileges.
	 * This query is meant to be run on the Cloud as admin roles
	 * are hidden from public and non-admin members.
	 */
  getQueryForUsersWithAdminPrivileges() {

    // const adminRoles = await this.admins.getRoles().query().find({ useMasterKey: true });
    // const queryUsersInRolesInAdminRole: Parse.Query<Parse.User>[] = adminRoles.map(role => role.getUsers().query());

    const queryAdminUsers = this.admins.getUsers().query();
    //return Parse.Query.or(queryAdminUsers); // Not working with .or()
    return queryAdminUsers;
  }

  getServiceName(): string {
    return ServicesOffered[this.service];
  }

  /**
	 * Get Profiles for users in the member role (not admins)
   * @param includeAdmins (deprecated) Not working
	 */
  async getTeamMembers(includeAdmins = false): Promise<Profile[]> {
    if (this.isNew()) return [];
    const params: DepartmentCloudRequestParams = { department: this.toPointer(), includeAdmins };
    return Parse.Cloud.run('department:getMembers', params, { useMasterKey: ENV.isServer });
  }

  async getPortfolio(): Promise<ProjectCase[]> {
    if (this.isNew()) return [];
    return new Parse.Query(ProjectCase)
      .equalTo('department', this.toPointer())
      .include('project')
      .find({ useMasterKey: ENV.isServer });
  }

  hasAdminPrivileges(user: Parse.User): Promise<boolean> {
    if (!this.admins) return Promise.resolve(false);

    const params: RoleHasUserParams = {
      user: user.toPointer(),
      role: this.admins.toPointer(),
      depth: 3
    }
    return Parse.Cloud.run('role:hasUser', params);
  }

  inviteMember(email: string): Promise<Profile | PendingInvite> {
    const params: DepartmentInviteMemberParams = { department: this.toPointer(), email: email };
    return Parse.Cloud.run('department:inviteMember', params);
  }

  /**
	 * Program this item for deletion
	 * @param hrs Hours to wait before deleting this element. Default is `48`
	 */
  programDeletion(hrs = 48) {
    const date = moment().add(hrs, 'hours').toDate();
    return this.save({ deleteOn: date }, { useMasterKey: ENV.isServer });
  }

  /**
	 * If this object is programmed to be deleted, it will un do that action.
	 */
  undelete() {
    return this.save({ deleteOn: null }, { useMasterKey: ENV.isServer });
  }

  async revokeAdmin(member: Profile) {
    if (!this.admins) return;
    this.admins.getUsers().remove(member.user);
    this.members.getUsers().add(member.user);
    await Promise.all([
      this.admins.save(null, { useMasterKey: ENV.isServer }),
      this.members.save(null, { useMasterKey: ENV.isServer }),
    ])
  }

  removeMember(user: Parse.User) {
    if (!this.members) return;
    this.members.getUsers().remove(user);
    return this.members.save(null, { useMasterKey: ENV.isServer });
  }

  // ================= Overwrite =======================

  // tslint:disable-next-line: no-any
  async save(attr?: any | null, options?: Parse.Object.SaveOptions): Promise<this> {
    await super.save(attr, options);

    // Return additional information created by the server
    return this.fetch();
  }
}

// IMPORTANT: Register as Parse subclass
Parse.Object.registerSubclass('Department', Department);
