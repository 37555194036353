import Parse from '@parse';
import { IParseObject } from '@library/parse-object';
import { Account } from '@library/account/account';
import { Profile } from '@library/profile/profile';
import { Company } from '@library/company/company';
import { Department } from '@library/department/department';
import { SignupData } from '@library/interfaces';
import { ENV } from '@app/env';
import { Tender } from '@library/tender/tender';

export interface IUser extends IParseObject {
  email: string,
  emailVerified?: boolean,
  mobile?: string,
  objectId?: string,
  password?: string,
  phone?: string,
  savedOpportunities?: Tender[],
  username?: string,
}

/**
 * This class holds private data for Users
 */
export class User extends Parse.User implements IUser {
  /**
	 * Company the user is currently signed in with.
	 */
  private company: Company;
  /**
	 * This property will be `undefined` before having called `getCompanies()`.
	 */
  private companies: Promise<Company[]>;
  /**
	 * This property will be `undefined` before having called `getProfile()`.
	 */
  private profile: Promise<Profile>;
  /**
	 * This property will be `undefined` before having called `getAccount()`.
	 */
  private account: Promise<Account>;

  constructor(userObject?: Parse.User | IUser) {
    if (userObject instanceof Parse.User) {
      const attr: IUser = {
        email: userObject.getEmail(),
        emailVerified: userObject.get('emailVerified'),
        mobile: userObject.get('mobile'),
        // tslint:disable-next-line: no-any
        objectId: userObject.id || (userObject as any).objectId,
        phone: userObject.get('phone'),
        savedOpportunities: userObject.get('savedOpportunities'),
        username: userObject.getUsername(),
      };

      // tslint:disable-next-line: no-any
      super(attr as any);
    } else if (!!userObject) {
      super(userObject as any);
    } else {
      super();
    }
  }

  get username(): string { return this.getUsername(); }
  set username(val: string) { this.setUsername(val); }

  get email(): string { return this.getEmail(); }
  set email(val: string) { this.setEmail(val); }

  get emailVerified(): boolean { return this.get('emailVerified'); }

  get mobile(): string { return this.get('mobile'); }
  set mobile(val: string) { this.set('mobile', val); }

  get phone(): string { return this.get('phone'); }
  set phone(val: string) { this.set('phone', val); }

  get savedOpportunities(): Tender[] {
    const arr = this.get('savedOpportunities');
    if (!arr) this.set('savedOpportunities', []);
    return this.get('savedOpportunities');
  }
  set savedOpportunities(val: Tender[]) {
    this.set('savedOpportunities', val);
  }

  static requestPasswordReset(email: string) {
    if (ENV.isServer)
      return super.requestPasswordReset(email);
    else
      return Parse.Cloud.run('resetPassword', { email });
  }

  static requestVerificationEmail(email: string) {
    return Parse.Cloud.run('verificationEmail', { email });
  }

  static signupUser(data: SignupData): Promise<Profile> {
    return Parse.Cloud.run('signup', data);
  }

  static current(): User {
    const current = Parse.User.current();
    return current && new User(current);
  }

  getAccount(force = false): Promise<Account> {
    if (!this.account || force)
      this.account = new Parse.Query(Account)
        .equalTo('owner', this.toPointer())
        .first({ useMasterKey: ENV.isServer });
    return this.account;
  }

  getProfile(): Promise<Profile> {
    if (!this.profile)
      this.profile = new Parse.Query(Profile)
        .equalTo('user', this.toPointer())
        .include('user')
        .first({ useMasterKey: ENV.isServer });
    return this.profile;
  }

  fetchSavedOpportunities(): Promise<Tender[]> {
    return new Parse.Query(Tender)
      .containedIn('objectId', this.savedOpportunities.map(o => o.id))
      .descending('deadline')
      .include('project')
      .find({ useMasterKey: false });
  }

  /**
	 * Gets the company this user is currently using.
	 * @see {@link User#setCompany}
	 * @param force If `true` the cached object is ignored and redownloaded
	 */
  async getCompany(force = false): Promise<Company> {
    if (!this.company) {
      const c = await this.getCompanies(force);
      this.company = c[0];
    }
    return this.company;
  }

  /**
	 * Gets the companies for this user and caches it.
	 * @param force If `true` the cached objects are ignored and data redownloaded
	 */
  getCompanies(force = false): Promise<Company[]> {
    if (!this.companies || force)
      this.companies = this.getCompanyQuery()
        .ascending('name')
        .find({ useMasterKey: ENV.isServer });

    return this.companies;
  }

  /**
	 * Gets the departments in which the user is enrolled
	 */
  getDepartments(enrolledAs: 'Admin' | 'Member' | 'Any' = 'Any'): Promise<Department[]> {
    return this.getDepartmentQuery(enrolledAs).find({ useMasterKey: ENV.isServer });
  }

  getCompanyQuery(): Parse.Query<Company> {
    const queryDepartments = this.getDepartmentQuery();
    const queryEmployerCompany = new Parse.Query(Company)
      .matchesKeyInQuery('objectId', 'company.objectId', queryDepartments);

    // Search by account
    const queryOwnCompany = new Parse.Query(Company)
      .matchesQuery('account', new Parse.Query(Account)
        .equalTo('owner', this.toPointer()));

    return Parse.Query.or(queryEmployerCompany, queryOwnCompany)
      .include('account');
  }

  getDepartmentQuery(enrolledAs: 'Admin' | 'Member' | 'Any' = 'Any'): Parse.Query<Department> {
    // Search by department
    const queryRolesWithUserInThem = new Parse.Query(Parse.Role).equalTo('users', this.toPointer());
    const queryRolesWithRolesWithUserInThem = new Parse.Query(Parse.Role).matchesQuery('roles', queryRolesWithUserInThem);
    const queryRoles = Parse.Query.or(queryRolesWithUserInThem, queryRolesWithRolesWithUserInThem);
    const queryDepartmentAsAdmin = new Parse.Query(Department).matchesQuery('admins', queryRoles);
    const queryDepartmentAsMember = new Parse.Query(Department).matchesQuery('members', queryRolesWithUserInThem);

    if (enrolledAs == 'Any')
      return Parse.Query.or(queryDepartmentAsMember, queryDepartmentAsAdmin);
    if (enrolledAs == 'Admin')
      return queryDepartmentAsAdmin;
    if (enrolledAs == 'Member')
      return queryDepartmentAsMember;
  }

  /**
	 * Retrieves the company linked to this user's account.
	 * @returns `null` if this user has not yet set it up.
	 */
  getOwnCompany(): Promise<Company> {
    return new Parse.Query(Company)
      .matchesQuery('account', new Parse.Query(Account)
        .equalTo('owner', this))
      .first({ useMasterKey: ENV.isServer });
  }

  /**
	 * Construct a query in wich the user is included
	 */
  getRoleQuery(): Parse.Query<Parse.Role> {
    const queryRolesWithUserInThem = new Parse.Query(Parse.Role).equalTo('users', this.toPointer());
    const queryRolesWithRolesWithUserInThem = new Parse.Query(Parse.Role).matchesQuery('roles', queryRolesWithUserInThem);
    const queryRoles = Parse.Query.or(queryRolesWithUserInThem, queryRolesWithRolesWithUserInThem);
    return queryRoles;
  }

  /**
	 * Sign this user in with a specific company
	 */
  async setCompany(id: string) {
    const companies = await this.getCompanies();
    this.company = companies.find(c => c.id == id);
    if (!this.company) throw new Error(`Company ${id} not found for this user`);
    return this.company;
  }
}
// IMPORTANT: Register as Parse subclass
Parse.Object.registerSubclass('_User', User);
