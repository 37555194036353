import { Injectable } from '@angular/core';
import { OpportunityListRequestParams } from '@library/cloud/interfaces';
import * as assign from 'assign-deep';

export interface Settings {
  auth: {
    companyId?: string
  },
  businessLounge?: OpportunityListRequestParams
}

@Injectable()
export class SettingsService {
  private _data: Settings = {
    auth: {},
    businessLounge: {
      limit: 20,
      order: 'updatedAt'
    }
  };

  constructor() {
    const data = localStorage.getItem('settings');
    const json = JSON.parse(data) || {};
    assign(this._data, json);
  }

  get data() { return this._data; }

  set(data: Partial<Settings>) {
    assign(this._data, data);
  }

  save() {
    const data = JSON.stringify(this._data);
    localStorage.setItem('settings', data);
  }
}
