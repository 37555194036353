import { Injectable, HostListener } from '@angular/core';
import { StripeCheckoutHandler, StripeCheckoutLoader } from 'ng-stripe-checkout';
import {
  StorePlan,
  StorePlansResponse,
  StoreResponse,
  StoreSubscribeParams,
  StoreVerifyCouponResponse,
  StorePurchasCreditsRequestParams
} from '@library/cloud/interfaces';
import { AuthService } from '@app/core/services/auth.service';
import Parse from '@parse';
import { Router } from '@angular/router';
import { PATH_CONSTANTS } from '../routes';
import { ICoupon } from '@library/coupon/coupon';
import { IntentionalError } from '../models/errors';

@Injectable()
export class PaymentService {
  private stripeCheckoutHandler: StripeCheckoutHandler;
  private _busy = false;
  private _plans: {
    annual: StorePlan,
    monthly: StorePlan,
    payg: StorePlan
    // tslint:disable-next-line: no-any
  } = {} as any;

  constructor(private stripeCheckoutLoader: StripeCheckoutLoader, private auth: AuthService, private router: Router) {
    this.init();
  }

  cancel() {
    this.stripeCheckoutHandler.close();
  }

  @HostListener('window:popstate')
  onPopstate() {
    this.stripeCheckoutHandler.close();
  }

  get busy() { return this._busy; }

  get ready() { return this.init; }

  async getPlans() {
    await this.ready();
    return this._plans;
  }

  async purchase(
    params: {
      units: number,
      planId?: string,
      couponData?: ICoupon,
    }): Promise<StoreResponse | false> {

    if (!this.stripeCheckoutHandler) throw new IntentionalError('You must first call init()');
    const company = await this.getCompany();
    if (!company) {
      this.router.navigate(['/', PATH_CONSTANTS.COMPANY]);
      throw new IntentionalError('You need to set up a company to purchase Tender Credits');
    }

    return new Promise<StoreResponse | false>(async (resolve, reject) => {
      this._busy = true;

      let description = `Purchase ${params.units} Tender Credits`;
      let applyCoupon = params.couponData && params.couponData.appliesTo == 'PAYG' && params.couponData || null;

      if (this._plans.monthly && params.planId === this._plans.monthly.id) {
        description = `${params.units} Monthly Credits Subscription`;
        applyCoupon = params.couponData && params.couponData.appliesTo == 'subscription' && params.couponData || null;
      } else if (this._plans.annual && params.planId === this._plans.annual.id) {
        description = `${params.units} Annual Credits Subscription`;
        applyCoupon = params.couponData && params.couponData.appliesTo == 'subscription' && params.couponData || null;
      } else {
        description = `Purchase ${params.units} Tender Credits`;
        applyCoupon = params.couponData && params.couponData.appliesTo == 'PAYG' && params.couponData || null;
      }

      // wait for UI to update
      setTimeout(async () => {
        try {
          const token = await this.stripeCheckoutHandler.open({
            name: applyCoupon && applyCoupon.name || 'SCOPE',
            amount: this.getPrice(params.units, params.planId, applyCoupon) * 100,
            currency: 'GBP',
            description,
            image: 'assets/img/scope_logo_sqr.png',
            allowRememberMe: true,
          })
          const response = await this.makePurchase(
            params.units,
            token.id,
            params.planId,
            applyCoupon && applyCoupon.couponCode);
          resolve(response);

        } catch (e) {
          if (e != 'stripe_closed')
            reject(e);
          else
            resolve(false)
        } finally {
          this._busy = false;
        }
      });
    })
  }

  /**
	 * Call this before atempting a purchase
	 */
  async init() {
    if (!this.stripeCheckoutHandler) {
      const response: StorePlansResponse = await Parse.Cloud.run('store:plans');
      this._plans = response.plans;
      this.stripeCheckoutHandler = await this.stripeCheckoutLoader.createHandler({ key: response.key });
    }
  }

  private async getCompany() {
    await this.auth.ready();
    const user = this.auth.currentUser;
    if (!user) throw new IntentionalError('Not logged in');
    return user && await user.getCompany();
  }


  private async makePurchase(units: number, token: string, planId?: string, couponId?: string) {
    let response: StoreResponse;
    const company = await this.getCompany();

    if (planId) {
      const params: StoreSubscribeParams = {
        company: company.toPointer(),
        plan: planId,
        token,
        units,
        couponId
      };
      response = await Parse.Cloud.run('store:purchaseSubscription', params);
    } else {
      const params: StorePurchasCreditsRequestParams = {
        company: company.toPointer(),
        token,
        units,
        couponCode: couponId
      };
      response = await Parse.Cloud.run('store:purchaseCredits', params);
    }

    const account = company.account;
    account.substractCredits(account.credits);
    account.addCredits(response.totalCredits);
    window.location.reload();
    return response;
  }

  /**
	 * In mayor units (not cents)
	 */
  getPrice(qty: number, planId?: string, couponData?: ICoupon): number {
    const plan = Object.keys(this._plans)
      .map(k => this._plans[k] as StorePlan)
      .find(p => p.id == planId);

    const tiers = plan && plan.tiers || [];

    let sum = 0;
    let i = 1;
    let tier;

    // Sum tier prices
    while (i <= qty) {
      tier = tiers.find(t => i <= t.up_to || t.up_to === null);
      sum += tier && tier.unit_amount || 0;
      i++;
    }

    // Add last tier's flat fee
    sum += tier && tier.flat_amount || 0;

    if (sum > 0 && couponData) {
      if (couponData.percent_off)
        sum *= (100 - couponData.percent_off) / 100;
      else
        sum -= couponData.amount_off || 0;
    }

    return Math.round(sum) / 100;
  }

  async verifyCoupon(couponId: string): Promise<StoreVerifyCouponResponse> {
    this._busy = true;
    try {
      const coupon: StoreVerifyCouponResponse = await Parse.Cloud.run('store:verifyCoupon', { id: couponId });
      if(!coupon.valid) throw new IntentionalError('Coupon expired');
      return coupon;
    } catch (e) {
      throw e;
    } finally {
      this._busy = false;
    }
  }
}
