import Parse from '@parse';
import { Company } from '@library/company/company';
import { Coords, TransportLink, AreaInformation, Meeting, TypeOfUse } from '@library/interfaces';
import { StripChar } from 'stripchar';
import { IParseObject } from '@library/parse-object';
import { ExtendedFile, UsesFilePointers } from '@library/file';
import { IProjectMilestones, ProjectMilestones, RibaStage } from '@library/milestones';
import { RoleHasUserParams, OpportunityListRequestParams, OpportunityListRequestResponse } from '@library/cloud/interfaces';
import { TypesOfUse, TypesOfDevelopment } from '@library/enums';
import { EvaluationCriteria } from '@library/evaluation/evaluation-criteria';

export interface IProject extends IParseObject {
  admins?: Parse.Role,
  areaInformation?: AreaInformation[],
  brief?: string,
  company: Company;
  constraints?: string[],
  constructionValueInGBP?: number,
  /** @deprecated Use `Project.brief` instead */
  desciption?: string,
  documentUrls?: string[],
  evaluationCriteria?: EvaluationCriteria,
  location?: Coords,
  address?: string,
  meetings?: Meeting[],
  NDAFileUrl?: string,
  photoUrls?: string[],
  procurementRoute?: number,
  providers?: IProjectProvider[],
  milestones?: IProjectMilestones
  title?: string;
  transportLinks?: TransportLink[],
  type?: number[],
  uses?: TypeOfUse[],
}

interface IProjectProvider {
  /** The service this company/person is providing to a project */
  service: string;
  /** The name of the company/person who provides the service in a project */
  companyName: string;
}

type AllButObjectId = Pick<IProject, Exclude<keyof IProject, 'objectId'>>;

export class Project extends UsesFilePointers implements Required<AllButObjectId>{
  unsavedPhotos: ExtendedFile[] = [];
  unsavedDocuments: ExtendedFile[] = [];

  constructor(attributes?: IProject) {
    super('Project', attributes);
  }

  get address(): string { return this.get('fullAddress'); }
  set address(val: string) { this.set('fullAddress', val); }

  get admins(): Parse.Role | null { return this.get('admins'); }
  set admins(val: Parse.Role | null) { this.set('admins', val); }

  get areaInformation(): AreaInformation[] {
    const arr = this.get('areaInformation');
    if (!arr) this.set('areaInformation', []);
    return this.get('areaInformation');
  }
  set areaInformation(val: AreaInformation[]) { this.set('areaInformation', val); }

  get brief(): string { return this.get('brief'); }
  set brief(val: string) { this.set('brief', val); }

  get company(): Company { return this.get('company'); }
  set company(val: Company) { this.set('company', val); }

  get constraints(): string[] {
    const arr = this.get('constraints');
    if (!arr) this.set('constraints', []);
    return this.get('constraints');
  }
  set constraints(val: string[]) { this.set('constraints', val); }

  get constructionValueInGBP(): number { return this.get('constructionValueInGBP'); }
  set constructionValueInGBP(val: number) { this.set('constructionValueInGBP', val); }

  /**
   * @deprecated Use `Project.brief` instead
   */
  get desciption(): string { return this.get('desciption'); }
  set desciption(val: string) { this.set('desciption', val); }

  get documents(): Parse.File[] {
    const arr = this.get('documents');
    if (!arr) this.set('documents', []);
    return this.get('documents');
  }
  set documents(val: Parse.File[]) { this.set('documents', val); }

  get documentNames(): string[] {
    return this.getFileNames(this.unsavedDocuments, this.documents);
  }

  get documentUrls(): string[] {
    return this.getFileUrls(this.unsavedDocuments, this.documents);
  }
  set documentUrls(urls: string[]) {
    this.documents = urls.map(url => {
      return new ExtendedFile(url);
    });
  }

  get evaluationCriteria(): EvaluationCriteria {
    const arr = this.get('evaluationCriteria');
    if (!(arr instanceof EvaluationCriteria))
      this.set('evaluationCriteria', new EvaluationCriteria(arr));
    return this.get('evaluationCriteria')
  }
  set evaluationCriteria(val: EvaluationCriteria) { this.set('evaluationCriteria', val); }

  get location(): Parse.GeoPoint { return this.get('location'); }
  set location(val: Parse.GeoPoint) { this.set('location', val); }

  get meetings(): Meeting[] {
    const arr = this.get('meetings');
    if (!arr) this.set('meetings', []);
    return this.get('meetings');
  }
  set meetings(val: Meeting[]) { this.set('meetings', val); }

  get milestones(): ProjectMilestones {
    const m = this.get('milestones');
    this.set('milestones', new ProjectMilestones(m));
    return this.get('milestones');
  }
  set milestones(val: ProjectMilestones) {
    if (!(val instanceof ProjectMilestones))
      val = new ProjectMilestones(val);

    this.set('milestones', val);
  }

  get NDAFile(): ExtendedFile {
    const file = this.get('NDAFile');
    if (file && !(file instanceof ExtendedFile))
      this.set('NDAFile', new ExtendedFile(file));
    return this.get('NDAFile');
  }
  set NDAFile(val: ExtendedFile) { this.set('NDAFile', val); }

  get NDAFileUrl(): string {
    return this.NDAFile && this.NDAFile.url();
  }
  set NDAFileUrl(url: string) {
    const filename = url.replace(/^.*[\\\/]/, '');
    this.NDAFile = {
      __type: 'File',
      name: StripChar.RSExceptUnsAlpNum(filename),
      url
      // tslint:disable-next-line: no-any
    } as any;
  }

  get photos(): Parse.File[] {
    const arr = this.get('photos');
    if (!arr) this.set('photos', []);
    return this.get('photos')
  }
  set photos(val: Parse.File[]) { this.set('photos', val); }

  get photoUrls(): string[] {
    return this.getFileUrls(this.unsavedPhotos, this.photos);
  }
  set photoUrls(urls: string[]) {

    this.photos = urls.map(url => {
      const filename = url.replace(/^.*[\\\/]/, '');
      const name = StripChar.RSExceptUnsAlpNum(filename);
      return new Parse.File(name, { url });

    });
  }

  get procurementRoute(): number { return this.get('procurementRoute'); }
  set procurementRoute(val: number) { this.set('procurementRoute', val); }

  get providers(): IProjectProvider[] {
    if (!this.get('providers')) this.set('providers', []);
    return this.get('providers');
  }
  set providers(val: IProjectProvider[]) { this.set('providers', val); }

  get title(): string { return this.get('title'); }
  set title(val: string) { this.set('title', val); }

  get transportLinks(): TransportLink[] { return this.get('transportLinks'); }
  set transportLinks(val: TransportLink[]) { this.set('transportLinks', val); }

  get type(): number[] {
    const arr = this.get('type');
    if (!arr) this.set('type', []);
    return this.get('type');
  }
  set type(val: number[]) { this.set('type', val); }

  get uses(): TypeOfUse[] {
    const arr = this.get('uses');
    if (!arr) this.set('uses', []);
    return this.get('uses');
  }
  set uses(val: TypeOfUse[]) { this.set('uses', val); }

  getClosingDate(): Date {
    let date: Date;
    for (let i = 7; i >= 0; i--) {
      const stage: RibaStage = this.milestones.riba['' + i];
      if (!date || stage.until > date) {
        date = stage.until;
      }
    }
    return date;
  }

  async getSimilarProjects(services: number[]) {
    const params: OpportunityListRequestParams = {
      filters: {
        services,
        uses: this.uses.map(u => u.type)
      },
      limit: 5
    };
    const response: OpportunityListRequestResponse = await Parse.Cloud.run('opportunity:list', params);
    return response.items.filter(it => it.projectId != this.id);
  }

  getStartingDate(): Date {
    let date: Date;
    for (let i = 7; i >= 0; i--) {
      const stage: RibaStage = this.milestones.riba['' + i];
      if (!date || stage.from < date) {
        date = stage.from;
      }
    }
    return date;
  }

  getConstructionArea(): number {
    if (!this.uses) return 0;

    if (this.uses.length > 1)
      return this.uses.map(u => u.areaInSqm * u.units).reduce((a, b) => a + b);
    else
      this.uses[0].areaInSqm;
  }

  getMilestones(): ProjectMilestones {
    return new ProjectMilestones(this.milestones);
  }

  getSummary(): string {
    const typeDescription = this.type.map(t => TypesOfDevelopment[t]).join(', ');
    const locationDescription = this.address ? `located in ${this.address}.` : '';
    const useDescription = this.getUseDetails().join(', ');
    return `${typeDescription} ${locationDescription} ${useDescription}.`;
  }

  /**
	 * Result in square meters
	 */
  getTotalAreaOfUse(typeOfUse?: number) {
    if (typeOfUse != null) {
      const tou = this.uses.find(u => u.type == typeOfUse);
      return (tou && tou.areaInSqm * (tou.units || 1)) || 0;
    } else {
      let sum = 0;
      this.uses.forEach(u => sum += (u.areaInSqm * (u.units || 1)) || 0);
      return sum;
    }
  }

  getUseDetails(): string[] {
    return this.uses.map(u => {
      const area = u.areaInSqm * u.units;
      const type = TypesOfUse[u.type];
      return `${area.toLocaleString()} m2 ${type}`;
    })
  }

  async hasAdminPrivileges(user: Parse.User): Promise<boolean> {
    if (!this.admins) return false;

    const params: RoleHasUserParams = {
      user: user.toPointer(),
      role: this.admins.toPointer(),
      depth: 3
    };
    return Parse.Cloud.run('role:hasUser', params);
  }

  /**
	 * A Project is active if any RIBA stage's end date has not passed.
	 */
  isActive() {
    return this.getClosingDate() > new Date();
  }

  setLocation(latitude: number, longitude: number) {
    this.location = new Parse.GeoPoint(latitude, longitude);
  }

  toJSON() {
    const json = super.toJSON();
    json.photoUrls = this.photoUrls;
    json.documentUrls = this.documentUrls;
    return json;
  }

  //================ File management methods =================

  addDocument(file: File) {
    this.addFile(file, this.unsavedDocuments);
  }

  addPhoto(file: File, base64?: string) {
    this.addFile(file, this.unsavedPhotos, base64);
  }

  removeDocument(index: number) {
    this.removeFile(index, this.documents, this.unsavedDocuments);
  }

  removePhoto(index: number) {
    this.removeFile(index, this.photos, this.unsavedPhotos);
  }

  async uploadPendingFiles(): Promise<void> {
    await Promise.all([
      this.uploadFilesInTempArray(this.unsavedPhotos, this.photos),
      this.uploadFilesInTempArray(this.unsavedDocuments, this.documents),
    ]);
  }
}

// IMPORTANT: Register as Parse subclass
Parse.Object.registerSubclass('Project', Project);
