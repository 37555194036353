import { Injectable, ErrorHandler, NgZone } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import Parse from '@parse';
import { AuthService } from '@app/core/services/auth.service';

import * as Sentry from '@sentry/browser';
import { ENV } from '@app/env';
import { IntentionalError } from '../models/errors';

function getVersion(): string {
  const version = document.getElementsByName('version')[0];
  return version && version.getAttribute('content');
}

if (!ENV.debug) { // No Sentry in debug mode: breadcrumbs are hard to read
  Sentry.init({
    environment: ENV.production ? 'production' : ENV.debug ? 'test' : 'unknown',
    dsn: 'https://7338087cc0334eaf92b5ca7860bf70af@sentry.io/1817816',
    release: getVersion()
  });
}

@Injectable()
export class ErrorHandlerService implements ErrorHandler {

  constructor(public notifier: NotifierService, public auth: AuthService, private ngZone: NgZone) { }

  handleError(error) {

    if (error.rejection) error = error.rejection;
    console.error(error);

    let msg = error.message || error;

    // Intentional Error ?
    if (error instanceof IntentionalError) {
      msg = error.message;
      this.notify(msg);
      return;
    }

    // Parse Error
    if (error instanceof Parse.Error) {

      // Handle specific ParseErrors
      switch (error.code) {
        case Parse.ErrorCode.EMAIL_TAKEN:
          msg += '(or email)';
          this.notify(msg);
          return;
        case Parse.ErrorCode.INVALID_SESSION_TOKEN:
          this.auth.logOut();
          this.notify(msg);
          return;
        case Parse.ErrorCode.OBJECT_NOT_FOUND:
          msg += ' (or missing privileges)'
          break;
        case Parse.ErrorCode.USERNAME_TAKEN:
          msg += ' (email)'
          this.notify(msg);
          return;
      }
    }
    // HTTP Error
    else if (error instanceof XMLHttpRequest) {
      msg = error.statusText || error.responseText || error;
    }

    // this.notifier.notify('error', msg);  // This will notify the user of an unhandled error

    // Report to Sentry
    if (location.hostname !== 'localhost') {
      const eventId = Sentry.captureException(error.originalError || error);
      // setTimeout(() => {
      //   Sentry.showReportDialog({ eventId });
      // }, 3e3);
    }
  }

  notify(msg: string) {
    this.ngZone.run(() => {
      this.notifier.notify('error', msg);
    });
  }
}
