/**************************************************
 * 		Define each page's absolute route path here
 **************************************************/

export const PATH_CONSTANTS = {
  ACCOUNT: 'account',
  BENCHMARK: 'benchmarking',
  COMPANY: 'company',
  EMPLOYER_DASHBOARD: 'employer-dashboard',
  HOME: '',
  LOGIN: 'login',
  POLICIES: 'policies',
  PRICING: 'pricing',
  PROFILE: 'profile',
  PROJECTS: 'projects',
  TENDER_DASHBOARD: 'tender-dashboard',
  TENDER_REPORT: 'tender-report'
};
