import Parse from '@parse';
import { User } from '@library/user/user';
import { IParseObject } from '@library/parse-object';
import { StoreHistoryResponse, StoreHistoryParams, StoreCharge } from '@library/cloud/interfaces';

export interface IAccount extends IParseObject {
  credits?: number,
  currentPlan?: string,
  customerId?: string,
  owner?: Parse.User,
}

interface IAccountSubscription {
  /** Amount of credits that are filled each period */
  credits: number,
  // /** Expiration date in ISO format */
  // expires: string,
  /** The plan ID on Stripe this customer purchased */
  planId: string,
  /** Subscription ID on Stripe */
  subscriptionId: string,
}

export class Account extends Parse.Object {

  constructor(attributes?: IAccount) {
    super('Account', attributes);
  }

  /**
	 * Return purchased credits (PAYG) plus the remaining subscription credits from this period.
	 */
  get credits(): number {
    return (this._credits || 0) + (this.subscription && this.subscription.credits || 0);
  }

  private get _credits(): number { return this.get('credits'); }
  private set _credits(val: number) { this.set('credits', val); }

  get subscription(): IAccountSubscription { return this.get('subscription'); }
  set subscription(val: IAccountSubscription) { this.set('subscription', val); }

  /**
	 * Customer ID on Stripe
	 */
  get customerId(): string { return this.get('customerId'); }
  set customerId(val: string) { this.set('customerId', val); }

  get owner(): User { return this.get('owner'); }
  set owner(val: User) { this.set('owner', val); }

  /**
	 * Add PAYG credits
	 * @returns new total credits
	 */
  addCredits(amount: number) {
    if (amount > 0) {
      this._credits += amount;
    }
    return this.credits;
  }

  /**
	 * Substract credits from subscription if any and or from PAYG credits if needed.
	 * @returns new total credits
	 */
  substractCredits(amount: number) {
    if (amount <= 0) return this.credits;

    if (this.subscription) {
      this.subscription.credits -= amount;

      // Take also from PAYG credits?
      if (this.subscription.credits < 0) {
        this._credits + this.subscription.credits;
        this.subscription.credits = 0;
      }

    } else {
      this._credits -= amount;
    }
    return this.credits;
  }

  async getPurchaseHistory(): Promise<StoreCharge[]> {
    const params: StoreHistoryParams = { customerId: this.customerId };
    const result: StoreHistoryResponse = await Parse.Cloud.run('store:history', params);
    return result.charges;
  }

}

// IMPORTANT: Register as Parse subclass
Parse.Object.registerSubclass('Account', Account);
