import Parse from '@parse';
import { Department } from '@library/department/department';
import { Bid } from '@library/bid/bid';

export class PendingInvite extends Parse.Object {

  constructor(params: { email: string, department: Department, bid?: Bid }) {
    if (params) {
      const ACL = new Parse.ACL({ '*': { read: false, write: false } });
      ACL.setRoleReadAccess(params.department.admins, true);
      ACL.setRoleWriteAccess(params.department.admins, true);
      super('PendingInvite', { ...params, ACL });
    } else {
      super('PendingInvite');
    }
  }

  get department(): Department { return this.get('department'); }
  set department(val: Department) { this.set('department', val); }

  get email(): string { return this.get('email'); }
  set email(val: string) { this.set('email', val); }

  get bid(): Bid { return this.get('bid'); }
  set bid(val: Bid) { this.set('bid', val); }
}

// IMPORTANT: Register as Parse subclass
Parse.Object.registerSubclass('PendingInvite', PendingInvite);
