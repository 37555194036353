import { Component, Output, EventEmitter, ViewChild, ElementRef, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Project } from '@library/project/project';
import { Company } from '@library/company/company';
import { NDARequest } from '@library/nda-request/nda-request';
import { ExtendedFile } from '@library/file';
import { AuthService } from '@app/core/services/auth.service';
import { AuthGuardService } from '@app/core/services/auth-guard.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-nda',
  templateUrl: './modal-nda.component.html',
  styleUrls: ['./modal-nda.component.scss']
})
export class ModalNDAComponent {
  @ViewChild('input', { static: true }) inputRef: ElementRef;
  @Output() dismiss = new EventEmitter<void>();
  project: Project;
  request: NDARequest;
  company: Company;

  constructor(private domSanitizer: DomSanitizer,
    private route: Router,
    private auth: AuthService,
    private authGuard: AuthGuardService) { }

  get pendingApproval(): boolean {
    return !!this.request && !this.request.isNew() && !this.request.approvedBy;
  }

  getSafeLink(url: string) {
    const link = this.domSanitizer.sanitize(SecurityContext.URL, url);
    return this.domSanitizer.bypassSecurityTrustResourceUrl(link);
  }

  async downloadNDA() {
    const canActivate = await this.authGuard.canActivate(null, this.route.routerState.snapshot);
    if (!canActivate) return;

    window.open(this.project.NDAFileUrl, '_system');
  }

  async onUploadClick() {
    const canActivate = await this.authGuard.canActivate(null, this.route.routerState.snapshot);
    if (!canActivate) return;

    this.company = await this.auth.currentUser.getCompany();
    (this.inputRef.nativeElement as HTMLInputElement).click();
  }

  async onFileSelected(input: HTMLInputElement) {
    const file = input.files[0];
    if (file) {
      this.request = new NDARequest({ project: this.project, bidder: this.company });
      this.request.signedFile = new ExtendedFile(file);
    }
  }

  async submit() {
    this.request.project.revert();
    this.request.bidder.revert();
    await this.request.save();
  }
}
