import { Component, OnDestroy, SecurityContext } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { Router } from '@angular/router';
import { Profile } from '@library/profile/profile';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { PATH_CONSTANTS } from '../routes';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {
  profile: Profile;
  subscription = new Subscription();
  paths = {
    login: PATH_CONSTANTS.LOGIN,
    publishProjects: PATH_CONSTANTS.EMPLOYER_DASHBOARD,
    winkWork: PATH_CONSTANTS.PROJECTS,
    pricing: PATH_CONSTANTS.PRICING
  };

  constructor(public auth: AuthService, private router: Router, private domSanitizer: DomSanitizer) {
    this.auth.ready().then(async () => {
      if (this.auth.isLoggedIn) this.profile = await this.auth.currentUser.getProfile();
      this.auth.loggedIn.subscribe(async (user) => this.profile = await user.getProfile());
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  get version(): string {
    const version = document.getElementsByName('version')[0];
    return version && version.getAttribute('content');
  }

  get userName(): string {
    const user = this.auth.currentUser;
    return (this.profile && this.profile.firstName)
      || (user && user.getEmail())
      || '';
  }

  logout() {
    return this.auth.logOut();
  }

  goto(path: string) {
    this.router.navigateByUrl(path);
  }

  get profilePic() {
    const url = this.profile && this.profile.photoUrl || 'https://picsum.photos/150';
    const style = `url('${this.domSanitizer.sanitize(SecurityContext.URL, url)}')`;
    return this.domSanitizer.bypassSecurityTrustStyle(style);
  }
}
