export const CorporateAccreditations = {
  WTS: 'WTS - Advancing Women in Transportation',
  AWMA: 'AWMA - Air & Waste Managment Association',
  AACE: 'AACE - American Association of Cost Engineers',
  ACRA: 'ACRA - American Cultural Resources Association',
  AIA: 'AIA - American Institute of Architects',
  AREMA: 'AREMA - American Railway Engineering and Maintenance-of-Way Association',
  ASEM: 'ASEM - American Society for Engineering Management',
  ASCE: 'ASCE - American Society of Civil Engineers',
  ASLA: 'ASLA - American Society of Landscape Architects',
  ASQ: 'ASQ - American Society of Quality',
  AWWA: 'AWWA - American Water Works Association',
  ACT: 'ACT - Association for Commuter Transportation',
  APM: 'APM - Association for Project Management',
  AGS: 'AGS - Association of Geotechnical and Geo-environmental Specialists',
  APEGA: 'APEGA - Association of Professional Engineers and Geoscientists of Alberta',
  AILA: 'AILA - Australian Institute of Landscape Architects',
  AIQS: 'AIQS - Australian Institute of Quantity Surveyors',
  AWA: 'AWA - Australian Water Association',
  BEM: 'BEM - Board of Engineers Malaysia',
  CIWEM: 'CIWEM - Charted Institute of Water and Environmental Management',
  CIHT: 'CIHT - Chartered Institution of Highways and Transport',
  CIAT: 'CIAT - Chartered Institute of Architectural Technologists',
  CIOB: 'CIOB - Chartered Institute of Building',
  CIEEM: 'CIEEM - Chartered Institute of Ecology & Environmental Management',
  CIBSE: 'CIBSE - Chartered Institution of Building Services Engineers',
  CBDG: 'CBDG - Concrete Bridge Development Group',
  CEB: 'CEB - Conference Executive Board Corporate Leadership Council (CLC)',
  CEB1: 'CEB - Corporate Executive Board',
  CII: 'CII - Construction Industry Institute',
  CMAA: 'CMAA - Construction Management Association of America',
  CESA: 'CESA - Consulting Engineers South Africa',
  DBIA: 'DBIA - Design Build Institute of America',
  EPRI: 'EPRI - Electric Power Reseach InstituteEI - Energy Institute',
  ECRI: 'ECRI - Engineering & Construction Risk Institute',
  IEI: 'IEI - Engineers IrelandGeolSoc - Geological Society',
  GBCA: 'GBCA - Green Buildings Council of Australia',
  IAEP: 'IAEP - Illinois Association of Environmental Professionals',
  ISCA: 'ISCA - Infrastructure Sustainability Council of Australia',
  IOA: 'IOA - Institute of Acoustics',
  IAQM: 'IAQM - Institute of Air Quality Management',
  IAM: 'IAM - Institute of Asset Management',
  IEMA: 'IEMA - Institute of Environmental Management & AssessmentIExpE - Institute of Explosive Engineers',
  IHE: 'IHE - Institute of Highway Engineers',
  IMESA: 'IMESA - Institute of Municipal Engineering of Southern Africa',
  INCE: 'INCE - Institute of Noise Control Engineering of the ',
  USAIPENZ: 'USAIPENZ - Institute of Professional Engineers New Zealand',
  ITE: 'ITE - Institute of Transportation Engineers',
  ICE: 'ICE - Institution of Civil Engineers',
  ICE1: 'ICE - Intitute of Civil EngineersLI - Landscape Institute',
  IET: 'IET - Institution of Engineering & Technology',
  IET1: 'IET - The Institution of Engineering & Technology',
  IED: 'IED - Institution of Engineering Designers',
  IES: 'IES - Institution of Environmental Sciences',
  IES1: 'IES - The Institution of Engineers, Singapore',
  IFE: 'IFE - Institution of Fire EngineersIMechE - Institution of Mechanical Engineers',
  IOSH: 'IOSH - Institution of Occupational Safety and HealthIstructE - Institution of Structural Engineers',
  IABSE: 'IABSE - International Association for Bridge and Structural Engineering',
  IAEM: 'IAEM - International Association of Emergency Managers',
  IIDA: 'IIDA - International Interior Design Association',
  LCI: 'LCI - Lean Construction Institute',
  MSECA: 'MSECA - Midwestern States Environmental Consultants Association',
  NAMS: 'NAMS - National Asset Management Support Group',
  NAIOP: 'NAIOP - National Association for Industrial and Office Parks',
  NAOC: 'NAOC - National Association of Ordnance Contractors',
  NEMA: 'NEMA - National Emergency Management Association',
  NFPA: 'NFPA - National Fire protection Association',
  NGWA: 'NGWA - National Groundwater Association',
  NOMA: 'NOMA - National Organization of Minority Architects',
  NZGBC: 'NZGBC - New Zealand Green Building Council',
  OACETT: 'OACETT - Ontario Association of Certified Engineering Technicians and Technologists',
  OCEC: 'OCEC - Orange County Engineering Council',
  PWI: 'PWI - Permanent Way Institution',
  PEO: 'PEO - Professional Engineers Ontario',
  PMI: 'PMI - Project Management InstituteQuest',
  CDN: 'CDN - Quest Construction Data Network',
  RGS: 'RGS - Royal Geographical Society',
  RIBA: 'RIBA - Royal Institution of British Architects',
  RICS: 'RICS - Royal Institution of Chartered Surveyors',
  RTPI: 'RTPI - Royal Town Planning Institute',
  SCSI: 'SCSI - Society of Chartered Surveyors Ireland',
  SFPE: 'SFPE - Society of Fire Protection Engineers',
  SWS: 'SWS - Society of Wetland Scientist',
  SAIA: 'SAIA - South African Institute of Architects',
  SABITA: 'SABITA - Southern African Bitumen Association',
  SAISC: 'SAISC - Southern African Institute of Steel Construction',
  SASTT: 'SASTT - Southern African Society for Trenchless Technology',
  ASAQS: 'ASAQS - The Association of South African Quantity Surveyors',
  GEAPS: 'GEAPS - The Grain Elevator and Processing Society',
  IEM: 'IEM - The Institution of Engineers, Malaysia',
  SAIMM: 'SAIMM - The Southern African Institute of Mining and Metallurgy',
  TPS: 'TPS - Transport Planning Society',
  TRB: 'TRB - Transportation Research Board',
  USGBC: 'USGBC - U.S. Green Building Council',
  ULI: 'ULI - Urban Land Institute',
  WERF: 'WERF - Water Environment Research FoundationWater NZ - Water New ZealandWaterRF - Water Research Foundation',
  WSAA: 'WSAA - Water Services Association of Australia',
}